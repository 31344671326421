import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Label } from './TypicalCuisine.styled';

interface Props {
  data?: string;
}

const TypicalCuisine: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(['establishments']);

  return (
    <Container className={'typical-cuisine'}>
      {t('typicalCuisine')} <Label>{props.data}</Label>
    </Container>
  );
};

export default TypicalCuisine;
