import React, { useState, useEffect } from 'react';

import maskPhone from 'utils/maskPhone';

import IconWhatsapp from 'res/svg/icon-whatsapp.svg';

import {
  List,
  ListItem,
  WhatsAppPhone,
  Phone,
  Icon,
} from './ListPhones.styled';

interface Props {
  whatsapp?: string;
  phone?: string;
  cellphone?: string;
}

const ListPhones: React.FC<Props> = (props: Props) => {
  const [whatsapp, setWhatsapp] = useState<string[]>([]);
  const [phone, setPhone] = useState<string[]>([]);
  const [cellphone, setCellphone] = useState<string[]>([]);

  useEffect(() => {
    if (props.whatsapp) {
      const arrWhatsapp = maskPhone(props.whatsapp);
      setWhatsapp(arrWhatsapp);
    }

    if (props.phone) {
      const arrPhone = maskPhone(props.phone);
      setPhone(arrPhone);
    }

    if (props.cellphone) {
      const arrCellphone = maskPhone(props.cellphone);
      setCellphone(arrCellphone);
    }
  }, [props.whatsapp, props.phone, props.cellphone]);

  return (
    <List className={'list-phones'}>
      {whatsapp.map(number => (
        <ListItem key={number}>
          <WhatsAppPhone
            target={'_blank'}
            rel={'noopener noreferrer'}
            href={`https://api.whatsapp.com/send?1=pt_BR&phone=55${number.replace(
              /\D/g,
              ''
            )}`}
          >
            <Icon src={IconWhatsapp} /> {number}
          </WhatsAppPhone>
        </ListItem>
      ))}

      {phone.map(number => (
        <ListItem key={number}>
          <Phone href={`tel:55${number.replace(/\D/g, '')}`}>{number}</Phone>
        </ListItem>
      ))}

      {cellphone.map(cellphone => (
        <ListItem key={cellphone}>
          <Phone href={`tel:55${cellphone.replace(/\D/g, '')}`}>
            {cellphone}
          </Phone>
        </ListItem>
      ))}
    </List>
  );
};

export default ListPhones;
