import styled from 'styled-components';

const H1 = styled.h1`
  color: ${props => props.theme.colors.secondary};
  font-family: ${props => props.theme.typography.h1.fontFamily};
  font-size: ${props => props.theme.typography.h1.fontSize};

  margin: 0 0 1.6rem 0;
  font-weight: normal;
  letter-spacing: 0.1pt;
  line-height: 3.6rem;

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    font-size: ${props => props.theme.typography.h1Mobile.fontSize};
    line-height: 3rem;
  }
`;

export default H1;
