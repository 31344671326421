import React from 'react';
import SubscribeFrom from 'react-mailchimp-subscribe';
import SimpleForm from './components/SimpleForm';

import 'res/css/mailchimp.css';

export interface FormDataProps {
  EMAIL: string;
}

interface MailchimpProps {
  subscribe: (formData: FormDataProps) => void;
  status: string;
  message: string;
}

const MailchimpForm: React.FC = () => {
  const url =
    'https://app.us19.list-manage.com/subscribe/post?u=9ae0435205531274db5eb255e&amp;id=033faa0597';

  return (
    <SubscribeFrom
      url={url}
      render={({ subscribe, status, message }: MailchimpProps) => (
        <SimpleForm
          className={'mailchimp-form'}
          status={status}
          message={message}
          onSubmitted={(formData: FormDataProps) => subscribe(formData)}
        />
      )}
    />
  );
};

export default MailchimpForm;
