import React from 'react';
import { useTranslation } from 'react-i18next';

import Map from 'res/images/map.gif';

import { Center, H1, H2 } from 'components';
import {
  Container,
  ContainerContent,
  ImageMap,
  Button,
  Info,
} from './SectionFilter.styled';

const SectionFilter: React.FC = () => {
  const { t } = useTranslation(['common', 'sectionFilter']);

  return (
    <Container id={'buscar-estabelecimentos'}>
      <Center>
        <ImageMap src={Map} />
        <ContainerContent>
          <H1 dangerouslySetInnerHTML={{ __html: t('sectionFilter:title') }} />
          <H2
            dangerouslySetInnerHTML={{
              __html: t('sectionFilter:subtitle'),
            }}
          />
          <ImageMap src={Map} />
          <Button to={'/buscar-estabelecimentos'}>
            {t('common:button.accessSearchTool')}
          </Button>
          <Info
            dangerouslySetInnerHTML={{
              __html: t('sectionFilter:info'),
            }}
          />
        </ContainerContent>
      </Center>
    </Container>
  );
};

export default SectionFilter;
