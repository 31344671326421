import { DefaultTheme } from 'styled-components';
import typography from './typography';
import breakpoints from './breakpoints';

const theme: DefaultTheme = {
  colors: {
    primary: '#FF651F',
    onPrimary: '#ffffff',
    secondary: '#444444',
    onSecondary: '#777777',
    secondaryAlpha40: 'rgba(119, 119, 119, 0.40)',
    secondaryAlpha25: 'rgba(119, 119, 119, 0.25)',
    tertiary: '#F4F4F4',
    onTertiary: '#EAEAEB',
    background: '#ffffff',
    backgroundAlpha10: 'rgba(255, 255, 255, 0.1)',
    backgroundAlpha75: 'rgba(255, 255, 255, 0.75)',
    backgroundAlpha50: 'rgba(255, 255, 255, 0.50)',
    onBackground: '#141414',
    error: '#ff5656',
    onError: '#ffffff',
    whatsapp: '#0DC752',
    instagram: '#E858AB',
    facebook: '#4367AF',
    twitter: '#34CCE8',
    crudi: '#46D1D6',
    vegan: '#40A766',
    veg: '#60DD8E',
    others: '#D9A4A4',
  },
  typography,
  breakpoints,
};

export default theme;
