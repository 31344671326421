import styled from 'styled-components';

export const Icon = styled.img`
  margin-right: 0.7rem;
  width: 1.5rem;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ListItem = styled.li`
  &:not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

export const WhatsAppPhone = styled.a`
  background-color: ${({ theme: { colors } }) => colors.whatsapp};
  color: ${({ theme: { colors } }) => colors.background};
  font-size: ${({ theme: { typography } }) => typography.button2.fontSize};

  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 2.8rem;
  text-decoration: none;
  border-radius: 0.4rem;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.veg};
  }
`;

export const Phone = styled(WhatsAppPhone)`
  color: ${({ theme: { colors } }) => colors.onSecondary};
  background-color: ${({ theme: { colors } }) => colors.onTertiary};

  font-weight: bold;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.tertiary};
  }
`;
