import styled, { css } from 'styled-components';

interface Props {
  horz?: boolean;
}

interface CircleProps {
  variant?: 'whatsapp' | 'facebook' | 'twitter';
}

export const IconAsset = styled.img``;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 5rem;
`;

export const List = styled.ul<Props>`
  display: flex;
  flex-direction: row;
  margin-bottom: 13.6rem;

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    flex-direction: ${({ horz }) => (horz ? 'row' : 'column')};
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin-bottom: 6rem;
  }
`;

export const ListItem = styled.li<CircleProps>`
  ${({ variant }) =>
    variant === 'whatsapp' &&
    css`
      span {
        background-color: ${({ theme: { colors } }) => colors.whatsapp};
      }
    `}

  ${({ variant }) =>
    variant === 'facebook' &&
    css`
      span {
        background-color: ${({ theme: { colors } }) => colors.facebook};
      }
    `}

  ${({ variant }) =>
    variant === 'twitter' &&
    css`
      span {
        background-color: ${({ theme: { colors } }) => colors.twitter};
      }
    `}

  &:not(:last-child) {
    margin-right: 4rem;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
      margin-right: 0;
    }
  }
`;

export const Media = styled.a`
  color: ${({ theme: { colors } }) => colors.onSecondary};
  font-size: ${({ theme: { typography } }) => typography.button2.fontSize};
  font-family: ${({ theme: { typography } }) => typography.button2.fontFamily};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  transition: opacity 0.25s ease-in-out 0s;
  user-select: none;

  &:hover,
  &:active {
    opacity: 0.5;
  }
`;

export const MediaButton = styled.button`
  color: ${({ theme: { colors } }) => colors.onSecondary};
  font-size: ${({ theme: { typography } }) => typography.button2.fontSize};
  font-family: ${({ theme: { typography } }) => typography.button2.fontFamily};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  transition: opacity 0.25s ease-in-out 0s;

  &:hover,
  &:active {
    opacity: 0.5;
  }
`;

export const MediaMobile = styled.div`
  a:first-of-type {
    display: inline-flex;
  }

  a:last-of-type {
    display: none;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    a:first-of-type,
    button:first-of-type {
      display: none;
    }

    a:last-of-type {
      display: inline-flex;
    }
  }
`;
