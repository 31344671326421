import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IconArrow from 'res/svg/arrow-left.svg';
import IconHamb from 'res/svg/hamb.svg';
import IconClose from 'res/svg/icon-close.svg';

import { H1, H2, Socials, MailchimpForm } from 'components';
import {
  Container,
  Icon,
  Header,
  Title,
  Anchor,
  ButtonHamb,
  ABack,
  Modal,
  List,
  Menu,
  ListItem,
  TitleMenu,
  ButtonClose,
  Copy,
} from './HeaderMobile.styled';

const HeaderMobile: React.FC = () => {
  const [toggle, setToggle] = useState(false);
  const location = useLocation();

  useEffect(() => setToggle(false), [location]);

  const { t } = useTranslation([
    'common',
    'establishments',
    'sectionFooter',
    'sectionCta',
  ]);

  return (
    <Container>
      <Header pathname={location.pathname}>
        {location.pathname === '/estabelecimentos' && (
          <>
            <ABack to={'/'}>
              <Icon src={IconArrow} />
            </ABack>
            <Title>{t('establishments:title')}</Title>
          </>
        )}
        <ButtonHamb onClick={() => setToggle(!toggle)}>
          <Icon src={IconHamb} />
        </ButtonHamb>
      </Header>
      <Modal className={toggle ? 'open' : 'close'}>
        <ButtonClose onClick={() => setToggle(false)}>
          <Icon src={IconClose} />
        </ButtonClose>
        <Menu>
          <List>
            <ListItem>
              <Anchor
                href={
                  'https://www.google.com/maps/d/u/0/viewer?mid=1-i1Aul4rPS_AxO2nO5qwcWd9KOxiUFVG&ll=-23.58542711160449%2C-46.66740292187501&z=12'
                }
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                {t('establishments:establishmentMap')}
              </Anchor>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <Anchor
                href={'https://forms.gle/4EdhoqhbCUuSBtc97'}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                {t('common:button.registerMyEstablishment')}
              </Anchor>
            </ListItem>
          </List>
          <TitleMenu>{t('establishments:appLauch')}</TitleMenu>
          <H2 dangerouslySetInnerHTML={{ __html: t('sectionCta:subtitle') }} />
          <MailchimpForm />
          <H1>{t('common:share')}</H1>
          <Socials hideText />
          <H2 dangerouslySetInnerHTML={{ __html: t('sectionFooter:help') }} />
          <Copy
            dangerouslySetInnerHTML={{ __html: t('sectionFooter:social') }}
          />
        </Menu>
      </Modal>
    </Container>
  );
};

export default HeaderMobile;
