import styled from 'styled-components';

export const Icon = styled.img``;

export const IconActive = styled.img``;

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  margin-bottom: 4rem;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 5rem;
`;

export const Label = styled.p`
  font-family: ${({ theme: { typography } }) => typography.body3.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.body3.fontSize};
  color: ${({ theme: { colors } }) => colors.onSecondary};

  margin-top: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1pt;
  text-align: center;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 2.4rem;
  }

  img:last-of-type {
    display: none;
  }

  ${Label} {
    color: ${({ theme: { colors } }) => colors.secondaryAlpha25};
  }

  &.active {
    img {
      &:first-of-type {
        display: none;
      }

      &:last-of-type {
        display: block;
      }
    }

    ${Label} {
      color: ${({ theme: { colors } }) => colors.secondary};
    }
  }

  &.item-crudi {
    &.active {
      ${Container} {
        background-color: ${({ theme: { colors } }) => colors.crudi};
      }
    }
  }

  &.item-vegan {
    &.active {
      ${Container} {
        background-color: ${({ theme: { colors } }) => colors.vegan};
      }
    }
  }

  &.item-veg {
    &.active {
      ${Container} {
        background-color: ${({ theme: { colors } }) => colors.veg};
      }
    }
  }

  &.item-others {
    &.active {
      ${Container} {
        background-color: ${({ theme: { colors } }) => colors.others};
      }
    }
  }
`;
