import styled from 'styled-components';

export const Icon = styled.div``;

export const IconAsset = styled.img``;

export const Device = styled.img`
  position: absolute;
  right: -348px;
  z-index: 0;
  transform: translateY(-50%);
  width: 100rem;
  height: auto;
  top: 15rem;
  pointer-events: none;

  @media ${({ theme: { breakpoints } }) => breakpoints.laptop} {
    right: -270px;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    right: -210px;
    margin-top: 2rem;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    position: relative;
    right: 0;
    transform: translate(0, -50%);
    max-width: none;
    width: 80rem;
    top: -8rem;
  }
`;

export const Col = styled.div`
  position: relative;
  z-index: 1;
  max-width: 720px;

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    order: 2;
    margin-top: -49.5rem;
    max-width: 100%;
  }
`;

export const Container = styled.section`
  background-color: ${({ theme: { colors } }) => colors.primary};
  color: ${({ theme: { colors } }) => colors.onPrimary};

  padding: 8.5rem 0 9rem 0;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-width: 720px;
  }

  h1,
  h2 {
    color: inherit;
  }

  h1 {
    margin-bottom: 4.5rem;
    max-width: 560px;
    width: 100%;
  }

  h2 {
    margin-bottom: 4.5rem;
    max-width: 612px;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.laptop} {
    h1 {
      max-width: 565px;

      br:last-of-type {
        display: none;
      }
    }

    h2 {
      max-width: 590px;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    padding: 4rem 0;

    h1 {
      max-width: 365px;
    }

    h2 {
      max-width: 390px;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding-bottom: 6.6rem;

    > div {
      flex-direction: column;
      min-width: 100%;
    }

    h1 {
      margin-top: 1rem;
      min-width: 100%;
    }

    h2 {
      margin-bottom: 4.4rem;
      max-width: 100%;
    }
  }
`;

export const Form = styled.div``;
