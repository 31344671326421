import styled from 'styled-components';

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  padding: 3.4rem 3rem 2rem 5.4rem;
  pointer-events: none;
  transition: transform 0.42s cubic-bezier(0.44, 0, 0.2, 1);

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding-top: 1.8rem;
    padding-left: 2rem;
    padding-right: 2.6rem;
  }
`;

export const ListHeader = styled.ul`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const ListItemHeader = styled.li`
  pointer-events: auto;

  a,
  button {
    span img:last-of-type {
      opacity: 1 !important;
    }

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.onTertiary};
    }
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:last-child {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
