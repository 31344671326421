import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  padding: 3.4rem 3rem 2rem 5.4rem;
  max-width: 375px;
  width: 100%;
  transform: translateX(-100%);
  transition: transform 0.42s cubic-bezier(0.44, 0, 0.2, 1);

  button {
    span img:last-of-type {
      opacity: 1 !important;
    }
  }

  &.show {
    transform: translateX(7rem);
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    max-width: calc(100% - 7rem);
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    max-width: 100%;

    &.show {
      transform: translateX(0);
    }
  }
`;
