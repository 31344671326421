import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme, DefaultTheme } from 'styled-components';

import IconPinBlack from 'res/svg/icon-pin-black.svg';
import IconWhatsapp from 'res/svg/icon-whatsapp-dark.svg';
import IconInstagram from 'res/svg/icon-instagram-dark.svg';
import IconFacebook from 'res/svg/icon-facebook-dark.svg';
// import IconTwitter from 'res/svg/icon-twitter-dark.svg';

import { EstablishmentProps } from '../../Types';

import HeaderDetails from './components/Header';
import { TypesRounds, TypicalCuisine, ListPhones } from 'components';

import {
  Icon,
  Container,
  Content,
  Title,
  Subtitle,
  ContentAddress,
  Address,
  ContainerPhones,
  ContainerProductTypes,
  ListProductTypes,
  ListItemProductTypes,
  ContainerOpeningHours,
  ListWeek,
  ListItemWeek,
  Day,
  ListHours,
  ListItemHours,
  Hours,
  Start,
  End,
  Closed,
  ContainerSocialProfiles,
  ListSocial,
  ListItemSocial,
  SocialAnchor,
  SocialName,
  SocialIcon,
} from './Detais.styled';

interface Props {
  theme: DefaultTheme;
  establishmentData?: EstablishmentProps;
  isLoadingRoute?: boolean;
  onClickClose?: () => void;
  onClickRoute?: (position?: string) => void;
}

let arrProductTypes: string[] = [];
let arrSU: string[] = [];
let arrMO: string[] = [];
let arrTU: string[] = [];
let arrWE: string[] = [];
let arrTH: string[] = [];
let arrFR: string[] = [];
let arrSA: string[] = [];

const Details: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(['establishments']);

  function splitHours(hour: string) {
    const arr = hour.split(',', 4);
    return arr;
  }

  if (props.establishmentData) {
    // Product Types
    const productTypes = props.establishmentData?.gsx$tiposdeprodutos?.$t;
    const splitProductTypes = productTypes?.split(', ');

    if (splitProductTypes) arrProductTypes = splitProductTypes;

    // Opening Hours
    const SU = props.establishmentData?.gsx$horáriodefuncionamentodomingo?.$t;
    const MO = props.establishmentData?.gsx$horáriodefuncionamentosegunda?.$t;
    const TU = props.establishmentData?.gsx$horáriodefuncionamentoterça?.$t;
    const WE = props.establishmentData?.gsx$horáriodefuncionamentoquarta?.$t;
    const TH = props.establishmentData?.gsx$horáriodefuncionamentoquinta?.$t;
    const FR = props.establishmentData?.gsx$horáriodefuncionamentosexta?.$t;
    const SA = props.establishmentData?.gsx$horáriodefuncionamentosábado?.$t;

    if (SU) arrSU = splitHours(SU);
    if (MO) arrMO = splitHours(MO);
    if (TU) arrTU = splitHours(TU);
    if (WE) arrWE = splitHours(WE);
    if (TH) arrTH = splitHours(TH);
    if (FR) arrFR = splitHours(FR);
    if (SA) arrSA = splitHours(SA);

    // console.log('Domingo', arrSU);
    // console.log('Segunda', arrMO);
    // console.log('Terça', arrTU);
    // console.log('Quarta', arrWE);
    // console.log('Quinta', arrTH);
    // console.log('Sexta', arrFR);
    // console.log('Sábado', arrSA);
  }

  useEffect(() => {
    const target = document.getElementById('modal-content');

    target?.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
  }, [props.establishmentData]);

  return (
    <Container>
      <HeaderDetails
        establishmentData={props.establishmentData}
        onClose={props.onClickClose}
        onClickRoute={props.onClickRoute}
      />

      <Content id={'modal-content'}>
        <Title>{props.establishmentData?.gsx$nomedoestabelecimento?.$t}</Title>

        <TypesRounds
          types={
            props.establishmentData?.gsx$tiposdealimentosqueoferecematriz?.$t
          }
        />

        <ContentAddress>
          <Icon src={IconPinBlack} />
          <Address>
            {props.establishmentData?.gsx$logradouro?.$t}{' '}
            {props.establishmentData?.gsx$número?.$t},{' '}
            {props.establishmentData?.gsx$bairro?.$t},{' '}
            {props.establishmentData?.gsx$cidade?.$t},{' '}
            {props.establishmentData?.gsx$estado?.$t}
          </Address>
        </ContentAddress>

        <TypicalCuisine data={props.establishmentData?.gsx$cozinhatípica?.$t} />

        <ContainerPhones>
          <Subtitle>{t('establishmentPhones')}</Subtitle>

          <ListPhones
            whatsapp={props.establishmentData?.gsx$whatsapp?.$t}
            phone={props.establishmentData?.gsx$telefonefixocomercial?.$t}
            cellphone={props.establishmentData?.gsx$celular?.$t}
          />
        </ContainerPhones>

        <ContainerProductTypes>
          <Subtitle>{t('productTypes')}</Subtitle>

          <ListProductTypes>
            {arrProductTypes.map((type, i: number) => (
              <ListItemProductTypes key={i}>{type}</ListItemProductTypes>
            ))}
          </ListProductTypes>
        </ContainerProductTypes>

        <ContainerOpeningHours>
          <Subtitle>{t('openingHours')}</Subtitle>

          <ListWeek>
            <ListItemWeek>
              <Day>{t('week.mo')}</Day>

              <ListHours>
                <ListItemHours>
                  {arrMO[0] === 'Fechado' || arrMO[1] === 'Fechado' ? (
                    <Closed>{t('closed')}</Closed>
                  ) : (
                    <>
                      {arrMO[0] && arrMO[1] && (
                        <Hours>
                          <Start>{arrMO[0] ? `${arrMO[0]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrMO[1] ? `${arrMO[1]}:00` : '00:00'}</End>
                        </Hours>
                      )}

                      {arrMO[2] && arrMO[3] && (
                        <Hours>
                          <Start>{arrMO[2] ? `${arrMO[2]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrMO[3] ? `${arrMO[3]}:00` : '00:00'}</End>
                        </Hours>
                      )}

                      {arrMO[4] && arrMO[5] && (
                        <Hours>
                          <Start>{arrMO[4] ? `${arrMO[4]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrMO[5] ? `${arrMO[5]}:00` : '00:00'}</End>
                        </Hours>
                      )}
                    </>
                  )}
                </ListItemHours>
              </ListHours>
            </ListItemWeek>

            <ListItemWeek>
              <Day>{t('week.tu')}</Day>

              <ListHours>
                <ListItemHours>
                  {arrTU[0] === 'Fechado' || arrTU[1] === 'Fechado' ? (
                    <Closed>{t('closed')}</Closed>
                  ) : (
                    <>
                      <Hours>
                        <Start>{arrTU[0] ? `${arrTU[0]}:00` : '00:00'}</Start>{' '}
                        às <End>{arrTU[1] ? `${arrTU[1]}:00` : '00:00'}</End>
                      </Hours>

                      {arrTU[2] && arrTU[3] && (
                        <Hours>
                          <Start>{arrTU[2] ? `${arrTU[2]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrTU[3] ? `${arrTU[3]}:00` : '00:00'}</End>
                        </Hours>
                      )}

                      {arrTU[4] && arrTU[5] && (
                        <Hours>
                          <Start>{arrTU[4] ? `${arrTU[4]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrTU[5] ? `${arrTU[5]}:00` : '00:00'}</End>
                        </Hours>
                      )}
                    </>
                  )}
                </ListItemHours>
              </ListHours>
            </ListItemWeek>

            <ListItemWeek>
              <Day>{t('week.we')}</Day>

              <ListHours>
                <ListItemHours>
                  {arrWE[0] === 'Fechado' || arrWE[1] === 'Fechado' ? (
                    <Closed>{t('closed')}</Closed>
                  ) : (
                    <>
                      <Hours>
                        <Start>{arrWE[0] ? `${arrWE[0]}:00` : '00:00'}</Start>{' '}
                        às <End>{arrWE[1] ? `${arrWE[1]}:00` : '00:00'}</End>
                      </Hours>

                      {arrWE[2] && arrWE[3] && (
                        <Hours>
                          <Start>{arrWE[2] ? `${arrWE[2]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrWE[3] ? `${arrWE[3]}:00` : '00:00'}</End>
                        </Hours>
                      )}

                      {arrWE[4] && arrWE[5] && (
                        <Hours>
                          <Start>{arrWE[4] ? `${arrWE[4]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrWE[5] ? `${arrWE[5]}:00` : '00:00'}</End>
                        </Hours>
                      )}
                    </>
                  )}
                </ListItemHours>
              </ListHours>
            </ListItemWeek>

            <ListItemWeek>
              <Day>{t('week.th')}</Day>

              <ListHours>
                <ListItemHours>
                  {arrTH[0] === 'Fechado' || arrTH[1] === 'Fechado' ? (
                    <Closed>{t('closed')}</Closed>
                  ) : (
                    <>
                      <Hours>
                        <Start>{arrTH[0] ? `${arrTH[0]}:00` : '00:00'}</Start>{' '}
                        às <End>{arrTH[1] ? `${arrTH[1]}:00` : '00:00'}</End>
                      </Hours>

                      {arrTH[2] && arrTH[3] && (
                        <Hours>
                          <Start>{arrTH[2] ? `${arrTH[2]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrTH[3] ? `${arrTH[3]}:00` : '00:00'}</End>
                        </Hours>
                      )}

                      {arrTH[4] && arrTH[5] && (
                        <Hours>
                          <Start>{arrTH[4] ? `${arrTH[4]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrTH[5] ? `${arrTH[5]}:00` : '00:00'}</End>
                        </Hours>
                      )}
                    </>
                  )}
                </ListItemHours>
              </ListHours>
            </ListItemWeek>

            <ListItemWeek>
              <Day>{t('week.fr')}</Day>

              <ListHours>
                <ListItemHours>
                  {arrFR[0] === 'Fechado' || arrFR[1] === 'Fechado' ? (
                    <Closed>{t('closed')}</Closed>
                  ) : (
                    <>
                      <Hours>
                        <Start>{arrFR[0] ? `${arrFR[0]}:00` : '00:00'}</Start>{' '}
                        às <End>{arrFR[1] ? `${arrFR[1]}:00` : '00:00'}</End>
                      </Hours>

                      {arrFR[2] && arrFR[3] && (
                        <Hours>
                          <Start>{arrFR[2] ? `${arrFR[2]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrFR[3] ? `${arrFR[3]}:00` : '00:00'}</End>
                        </Hours>
                      )}

                      {arrFR[4] && arrFR[5] && (
                        <Hours>
                          <Start>{arrFR[4] ? `${arrFR[4]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrFR[5] ? `${arrFR[5]}:00` : '00:00'}</End>
                        </Hours>
                      )}
                    </>
                  )}
                </ListItemHours>
              </ListHours>
            </ListItemWeek>

            <ListItemWeek>
              <Day>{t('week.sa')}</Day>

              <ListHours>
                <ListItemHours>
                  {arrSA[0] === 'Fechado' || arrSA[1] === 'Fechado' ? (
                    <Closed>{t('closed')}</Closed>
                  ) : (
                    <>
                      <Hours>
                        <Start>{arrSA[0] ? `${arrSA[0]}:00` : '00:00'}</Start>{' '}
                        às <End>{arrSA[1] ? `${arrSA[1]}:00` : '00:00'}</End>
                      </Hours>

                      {arrSA[2] && arrSA[3] && (
                        <Hours>
                          <Start>{arrSA[2] ? `${arrSA[2]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrSA[3] ? `${arrSA[3]}:00` : '00:00'}</End>
                        </Hours>
                      )}

                      {arrSA[4] && arrSA[5] && (
                        <Hours>
                          <Start>{arrSA[4] ? `${arrSA[4]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrSA[5] ? `${arrSA[5]}:00` : '00:00'}</End>
                        </Hours>
                      )}
                    </>
                  )}
                </ListItemHours>
              </ListHours>
            </ListItemWeek>

            <ListItemWeek>
              <Day>{t('week.su')}</Day>

              <ListHours>
                <ListItemHours>
                  {arrSU[0] === 'Fechado' || arrSU[1] === 'Fechado' ? (
                    <Closed>{t('closed')}</Closed>
                  ) : (
                    <>
                      <Hours>
                        <Start>{arrSU[0] ? `${arrSU[0]}:00` : '00:00'}</Start>{' '}
                        às <End>{arrSU[1] ? `${arrSU[1]}:00` : '00:00'}</End>
                      </Hours>

                      {arrSU[2] && arrSU[3] && (
                        <Hours>
                          <Start>{arrSU[2] ? `${arrSU[2]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrSU[3] ? `${arrSU[3]}:00` : '00:00'}</End>
                        </Hours>
                      )}

                      {arrSU[4] && arrSU[5] && (
                        <Hours>
                          <Start>{arrSU[4] ? `${arrSU[4]}:00` : '00:00'}</Start>{' '}
                          às <End>{arrSU[5] ? `${arrSU[5]}:00` : '00:00'}</End>
                        </Hours>
                      )}
                    </>
                  )}
                </ListItemHours>
              </ListHours>
            </ListItemWeek>
          </ListWeek>
        </ContainerOpeningHours>

        <ContainerSocialProfiles>
          <Subtitle>{t('socialProfiles')}</Subtitle>

          <ListSocial>
            {props.establishmentData?.gsx$whatsapp?.$t && (
              <ListItemSocial>
                <SocialAnchor
                  href={`http://api.whatsapp.com/send?1=pt_BR&phone=55${props.establishmentData?.gsx$whatsapp?.$t}`}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <SocialIcon>
                    <Icon src={IconWhatsapp} />
                  </SocialIcon>
                  <SocialName>{t('whatsapp')}</SocialName>
                </SocialAnchor>
              </ListItemSocial>
            )}

            {props.establishmentData?.gsx$instagram?.$t && (
              <ListItemSocial>
                <SocialAnchor
                  href={props.establishmentData?.gsx$instagram?.$t}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <SocialIcon>
                    <Icon src={IconInstagram} />
                  </SocialIcon>
                  <SocialName>{t('instagram')}</SocialName>
                </SocialAnchor>
              </ListItemSocial>
            )}

            {props.establishmentData?.gsx$facebook?.$t && (
              <ListItemSocial>
                <SocialAnchor
                  href={props.establishmentData?.gsx$facebook?.$t}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <SocialIcon>
                    <Icon src={IconFacebook} />
                  </SocialIcon>
                  <SocialName>{t('facebook')}</SocialName>
                </SocialAnchor>
              </ListItemSocial>
            )}

            {/* {props.establishmentData?.gsx$twitter?.$t && (
                <ListItemSocial>
                  <SocialAnchor
                    href={props.establishmentData?.gsx$twitter?.$t}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    <SocialIcon>
                      <Icon src={IconTwitter} />
                    </SocialIcon>
                    <SocialName>{t('twitter')}</SocialName>
                  </SocialAnchor>
                </ListItemSocial>
              )} */}
          </ListSocial>
        </ContainerSocialProfiles>
      </Content>
    </Container>
  );
};

export default withTheme(Details);
