import styled from 'styled-components';

const Button = styled.button`
  font-family: ${({ theme: { typography } }) => typography.button1.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  color: ${({ theme: { colors } }) => colors.primary};
  background-color: ${({ theme: { colors } }) => colors.background};

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  height: 4.6rem;
  border-radius: 0.6rem;
  transition: opacity 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;
  outline: none;

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

export default Button;
