import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  search?: boolean;
  variant?: 'light' | 'dark';
}

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Input = styled.input<HTMLAttributes<HTMLInputElement> & Props>`
  border: 0;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
  color: ${({ theme: { colors } }) => colors.secondary};
  border-bottom-color: ${({ theme: { colors } }) => colors.secondaryAlpha40};

  padding: 0 0 0 0.6rem;
  background-color: transparent;
  width: 100%;
  height: 3.6rem;
  outline: none;

  ${({ search }) =>
      search &&
      css`
        text-indent: 3.1rem;
        padding-right: 4rem;
      `}
    ::placeholder {
    color: ${({ theme: { colors } }) => colors.secondaryAlpha40};
  }

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme: { colors } }) => colors.background};
      border-bottom-color: ${({ theme: { colors } }) =>
        colors.backgroundAlpha75};

      ::placeholder {
        color: ${({ theme: { colors } }) => colors.backgroundAlpha50};
      }
    `}
`;

export const Icon = styled.div`
  position: absolute;
  top: 7px;
  left: 5px;
`;

export const IconAsset = styled.img``;

export const Button = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 2px;
  right: -55px;
  padding: 0.5rem 1.9rem;
`;
