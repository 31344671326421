import styled from 'styled-components';

export const Col = styled.div`
  position: relative;
  z-index: 1;
`;

export const ContainerAsset = styled.span``;

export const Container = styled.section`
  padding: 8.7rem 0 7.4rem 0;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ${Col}:first-of-type {
    width: 48rem;
  }

  ${Col}:last-of-type {
    max-width: 549px;
  }

  h2 {
    margin-bottom: 4.5rem;
    max-width: 510px;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${Col}:first-of-type {
      width: 40rem;
    }

    ${Col}:last-of-type {
      max-width: 500px;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    ${Col}:first-of-type {
      width: 30rem;
    }

    ${Col}:last-of-type {
      max-width: 350px;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    > div {
      flex-direction: column;

      ${ContainerAsset} {
        display: block;
        margin: 0 auto;
      }
    }

    ${Col}:first-of-type {
      margin-bottom: 5.1rem;
      width: 100%;
    }

    ${Col}:last-of-type {
      max-width: 100%;
    }

    h2 {
      max-width: 100%;
      margin-bottom: 5rem;
    }
  }
`;

export const ButtonRegister = styled.a`
  font-family: ${({ theme: { typography } }) => typography.button1.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  color: ${({ theme: { colors } }) => colors.background};
  background-color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  max-width: 33.7rem;
  height: 4.6rem;
  width: 100%;
  border-radius: 0.6rem;
  text-decoration: none;
  transition: opacity 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin: 0 auto;
  }
`;
