import styled from 'styled-components';

export const Icon = styled.img`
  margin-bottom: 5rem;
  width: 10rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 5rem;
  width: 100%;
  text-align: center;

  h2 {
    font-size: ${({ theme: { typography } }) => typography.h2Mobile.fontSize};
    max-width: 410px;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding: 2rem 2rem 0 2rem;
  }
`;

export const ButtonRegister = styled.a`
  font-family: ${({ theme: { typography } }) => typography.button1.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  color: ${({ theme: { colors } }) => colors.background};
  background-color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  padding: 0 3rem;
  max-width: 300px;
  height: 4.6rem;
  width: 100%;
  border-radius: 0.6rem;
  text-decoration: none;
  transition: opacity 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;
