import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize};

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
    height: 100%;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Raleway-Regular', 'Helvetica', 'Arial', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body1.fontSize};
    -webkit-tap-highlight-color: transparent;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;

    &.block {
      overflow: hidden;
    }

    @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
      &.fixed {
        position: fixed;
        width: 100%;
      }
    }

    @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
      &.fixed {
        position: static;
      }
    }
  }

  a {
    outline: none;
    user-select: none;

    &:focus {
      color: none;
      background-color: none;
      opacity: 0.5;
    }
  }

  button {
    user-select: none;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    outline: none;
    user-select: none;
    cursor: pointer;

    &:focus {
      opacity: 0.5;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  img {
    max-width: 100%;
    user-select: none;
  }

  p {
    margin: 0;
  }

  strong {
    color: ${({ theme: { colors } }) => colors.primary};
    font-family: ${({ theme: { typography } }) => typography.body2.fontFamily};
    font-size: ${({ theme: { typography } }) => typography.body2.fontSize};
  }

  #root {
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
  }

  ::selection {
    color: ${({ theme: { colors } }) => colors.background};
    background-color: ${({ theme: { colors } }) => colors.primary};
  }

  .pac-container {
    box-shadow: none;

    .pac-item {
      padding: 1rem;
      transition: color 0.25s ease-in-out 0s, background-color 0.25s ease-in-out 0s;
      cursor: pointer;

      span {
        color: ${({ theme: { colors } }) => colors.onSecondary};
        font-size: ${({ theme: { typography } }) =>
          typography.button2.fontSize};
      }

      &:hover {
        color: ${({ theme: { colors } }) => colors.background};
        background-color: ${({ theme: { colors } }) => colors.tertiary};
      }
    }

    &:after {
      display: none;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    .pac-container {
      top: 68px !important;
      left: 0 !important;
      width: 100% !important;
    }
  }
`;

export default GlobalStyle;
