import React from 'react';
import { useTranslation } from 'react-i18next';

import IconHeart from 'res/svg/heart.svg';

import { H1, H2 } from 'components';

import { Container, Icon, ButtonRegister } from './EmptyList.styled';

const EmptyList: React.FC = () => {
  const { t } = useTranslation(['establishments']);

  return (
    <Container>
      <Icon src={IconHeart} />

      <H1>{t('establishments:notFoundTitle')}</H1>

      <H2
        dangerouslySetInnerHTML={{
          __html: t('establishments:notFoundSubtitle'),
        }}
      />

      <ButtonRegister
        href={'https://forms.gle/4EdhoqhbCUuSBtc97'}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        {t('common:button.registerMyEstablishment')}
      </ButtonRegister>
    </Container>
  );
};

export default EmptyList;
