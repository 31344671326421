import React from 'react';
import { withTheme, DefaultTheme } from 'styled-components';

import IconLocation from 'res/svg/icon-location.svg';
import IconLink from 'res/svg/icon-link.svg';
import IconLinkLight from 'res/svg/icon-link-light.svg';
import IconCar from 'res/svg/icon-car.svg';
import IconCarLight from 'res/svg/icon-car-light.svg';
import IconMore from 'res/svg/icon-options.svg';
import IconMoreLight from 'res/svg/icon-options-light.svg';

import { EstablishmentProps } from '../../pages/Establishments/Layout/Types';

import { ListTypes, TypicalCuisine, ListPhones, ButtonIcon } from 'components';

import {
  ListItem,
  Wrap,
  Icon,
  IconAsset,
  Content,
  ButtonName,
  Address,
  HeaderItem,
  CtaList,
  CtaListItem,
} from './ItemEstablishment.styled';

interface Props {
  theme: DefaultTheme;
  id?: string;
  style?: any;
  establishmentData?: EstablishmentProps;
  isLoadingRoute?: boolean;
  onClickRoute?: (position?: string) => void;
  onClickItem?: (position: any) => void;
  onClickDescription?: () => void;
}

const ItemEstablishment: React.FC<Props> = (props: Props) => {
  return (
    <ListItem id={props.id} style={props.style}>
      <Wrap>
        <Icon>
          <IconAsset src={IconLocation} />
        </Icon>

        <Content>
          <HeaderItem>
            <ButtonName
              className={'item-title'}
              onClick={() =>
                props.onClickItem && props.onClickItem(props.establishmentData)
              }
            >
              {props.establishmentData?.gsx$nomedoestabelecimento?.$t}
            </ButtonName>

            <CtaList>
              {props.establishmentData?.gsx$website?.$t && (
                <CtaListItem>
                  <ButtonIcon
                    type={'anchor'}
                    icon={IconLink}
                    href={props.establishmentData?.gsx$website?.$t}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    iconHover={IconLinkLight}
                  />
                </CtaListItem>
              )}

              <CtaListItem>
                <ButtonIcon
                  name={'button-router'}
                  onClick={() =>
                    props.onClickRoute &&
                    props.onClickRoute(
                      props.establishmentData?.gsx$geolocalização?.$t
                    )
                  }
                  icon={IconCar}
                  iconHover={IconCarLight}
                />
              </CtaListItem>

              <CtaListItem
                onClick={() => {
                  props.onClickDescription && props.onClickDescription();
                }}
              >
                <ButtonIcon
                  name={'button-description'}
                  icon={IconMore}
                  iconHover={IconMoreLight}
                />
              </CtaListItem>
            </CtaList>
          </HeaderItem>

          <ListTypes
            types={
              props.establishmentData?.gsx$tiposdealimentosqueoferecematriz?.$t
            }
          />

          <Address>
            {props.establishmentData?.gsx$logradouro?.$t}{' '}
            {props.establishmentData?.gsx$número?.$t},{' '}
            {props.establishmentData?.gsx$bairro?.$t},{' '}
            {props.establishmentData?.gsx$cidade?.$t},{' '}
            {props.establishmentData?.gsx$estado?.$t}
          </Address>

          <TypicalCuisine
            data={props.establishmentData?.gsx$cozinhatípica?.$t}
          />

          <ListPhones
            whatsapp={props.establishmentData?.gsx$whatsapp?.$t}
            phone={props.establishmentData?.gsx$telefonefixocomercial?.$t}
            cellphone={props.establishmentData?.gsx$celular?.$t}
          />
        </Content>
      </Wrap>
    </ListItem>
  );
};

export default withTheme(ItemEstablishment);
