import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.section`
  background-color: ${({ theme: { colors } }) => colors.tertiary};
  padding: 7.3rem 0 9.5rem 0;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  h1 {
    margin-bottom: 3rem;
  }

  h2 {
    margin-bottom: 3rem;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding-bottom: 33.2rem;

    > div {
      flex-direction: column;

      > img {
        display: none;
      }
    }

    h2 {
      margin-bottom: 3.1rem;
    }
  }
`;

export const ContainerContent = styled.div`
  max-width: 588px;

  > img {
    display: none;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    max-width: 375px;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    max-width: 100%;

    img {
      display: block;
    }
  }
`;

export const ImageMap = styled.img`
  margin-left: 6rem;
  width: 28rem;

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    margin-left: 0;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: block;
    margin: 0 auto 6rem auto;
  }
`;

export const Button = styled(Link)`
  font-family: ${({ theme: { typography } }) => typography.button1.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  color: ${({ theme: { colors } }) => colors.background};
  background-color: ${({ theme: { colors } }) => colors.primary};

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.4rem;
  padding: 0 3rem;
  max-width: 337px;
  height: 4.6rem;
  border-radius: 0.6rem;
  text-decoration: none;
  transition: opacity 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin: 0 auto 3.4rem auto;
  }
`;

export const Info = styled.p`
  color: ${({ theme: { colors } }) => colors.onSecondary};
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  line-height: 2.6rem;

  strong {
    font-family: inherit;
    font-size: inherit;
  }
`;
