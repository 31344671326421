import styled from 'styled-components';

const H2 = styled.h2`
  color: ${props => props.theme.colors.onSecondary};
  font-family: ${props => props.theme.typography.h2.fontFamily};
  font-size: ${props => props.theme.typography.h2.fontSize};

  margin: 0;
  font-weight: normal;
  letter-spacing: 0.16pt;
  line-height: 2.6rem;

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    font-size: ${props => props.theme.typography.h2Mobile.fontSize};
  }
`;

export default H2;
