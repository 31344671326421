import React from 'react';

import Cellphone from 'res/images/device.png';

import { Center, H1, H2, MailchimpForm } from 'components';
import { Col, Container, Device, Form } from './SectionCta.styled';

interface Props {
  title?: string;
  subtitle?: string;
}

const SectionCta: React.FC<Props> = (props: Props) => {
  return (
    <Container>
      <Center>
        <Col>
          <H1 dangerouslySetInnerHTML={{ __html: `${props.title}` }} />
          <H2 dangerouslySetInnerHTML={{ __html: `${props.subtitle}` }} />
          <Form>
            <MailchimpForm />
          </Form>
        </Col>
        <Device src={Cellphone} />
      </Center>
    </Container>
  );
};

export default SectionCta;
