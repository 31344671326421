import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ScrollToTop, HeaderMobile } from 'components';
import { Home, Establishments } from 'pages';

const Routes: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <HeaderMobile />
          <Home />
        </Route>
        <Route path="/buscar-estabelecimentos">
          <Establishments />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
