import React from 'react';
import { useTranslation } from 'react-i18next';

import Crudi from 'res/svg/icon-crudi.svg';
import CrudiActive from 'res/svg/icon-crudi-active.svg';

import Vegan from 'res/svg/icon-vegan.svg';
import VeganActive from 'res/svg/icon-vegan-active.svg';

import Veg from 'res/svg/icon-veg.svg';
import VegActive from 'res/svg/icon-veg-active.svg';

import Others from 'res/svg/icon-others.svg';
import OthersActive from 'res/svg/icon-others-active.svg';

import {
  List,
  ListItem,
  Container,
  Icon,
  IconActive,
  Label,
} from './TypesRounds.styled';

interface Props {
  types?: string;
}

let types: string[] = [];

const TypesRounds: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  if (props.types) types = props.types.split(', ');

  return (
    <List>
      <ListItem
        className={`item-crudi ${
          types.includes(
            'Opções Crudiveganas (Cruas a no máximo com cocção até 40o)'
          )
            ? 'active'
            : ''
        }`}
      >
        <Container>
          <Icon src={Crudi} />
          <IconActive src={CrudiActive} />
        </Container>

        <Label>{t('common:types.0.label')}</Label>
      </ListItem>

      <ListItem
        className={`item-vegan ${
          types.includes('Opções Veganas') ? 'active' : ''
        }`}
      >
        <Container>
          <Icon src={Vegan} />
          <IconActive src={VeganActive} />
        </Container>

        <Label>{t('common:types.1.label')}</Label>
      </ListItem>

      <ListItem
        className={`item-veg  ${
          types.includes('Opções Vegetarianas (Ovolacto)') ? 'active' : ''
        }`}
      >
        <Container>
          <Icon src={Veg} />
          <IconActive src={VegActive} />
        </Container>

        <Label>{t('common:types.2.label')}</Label>
      </ListItem>

      <ListItem
        className={`item-others ${
          types.includes('Opções com carne') ? 'active' : ''
        }`}
      >
        <Container>
          <Icon src={Others} />
          <IconActive src={OthersActive} />
        </Container>

        <Label>{t('common:types.3.label')}</Label>
      </ListItem>
    </List>
  );
};

export default TypesRounds;
