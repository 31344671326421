import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

import deliveryIllustration from 'res/bodymovin/delivery-illustration.json';

import { Center, H1, H2 } from 'components';
import {
  Container,
  Col,
  ButtonRegister,
  ContainerAsset,
} from './SectionNotFind.styled';

interface Props {
  title?: string;
  subtitle?: string;
}

const SectionNotFind: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(['common', 'sectionNotFind']);

  const defaultOptions = {
    autoplay: true,
    animationData: deliveryIllustration,
  };

  return (
    <Container>
      <Center>
        <Col>
          <ContainerAsset>
            <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
          </ContainerAsset>
        </Col>
        <Col>
          <H1 dangerouslySetInnerHTML={{ __html: `${props.title}` }} />
          <H2 dangerouslySetInnerHTML={{ __html: `${props.subtitle}` }} />
          <ButtonRegister
            href={'https://forms.gle/4EdhoqhbCUuSBtc97'}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {t('common:button.registerMyEstablishment')}
          </ButtonRegister>
        </Col>
      </Center>
    </Container>
  );
};

export default SectionNotFind;
