import styled from 'styled-components';

export const Container = styled.section`
  background-color: ${({ theme: { colors } }) => colors.tertiary};
  display: flex;
  flex-direction: column;
  padding: 6.8rem 0 5.3rem 0;

  h2 {
    margin-bottom: 5rem;
    max-width: 898px;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    h1 {
      margin-bottom: 3rem;

      br {
        display: none;
      }
    }

    h2 {
      margin-bottom: 3.5rem;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding-top: 4.5rem;
  }
`;

export const HelpText = styled.p`
  color: ${({ theme: { colors } }) => colors.onSecondary};
  font-size: ${({ theme: { typography } }) => typography.body2.fontSize};
  line-height: 2.6rem;

  > a {
    font-family: ${({ theme: { typography } }) =>
      typography.button2.fontFamily};
    color: inherit;
    text-decoration: none;
    transition: opacity 0.25s ease-in-out 0s;

    &:hover,
    &:active {
      opacity: 0.5;
    }
  }
`;

export const Copy = styled.p`
  color: ${({ theme: { colors } }) => colors.secondary};
  font-size: ${({ theme: { typography } }) => typography.body1.fontSize};
  margin-top: 1.3rem;

  > a {
    font-family: ${({ theme: { typography } }) =>
      typography.button2.fontFamily};
    color: inherit;
    text-decoration: none;
    transition: opacity 0.25s ease-in-out 0s;

    &:hover,
    &:active {
      opacity: 0.5;
    }
  }
`;
