import React from 'react';
import { useTranslation } from 'react-i18next';

import { List, ListItem } from './ListTypes.styled';

interface Props {
  types?: string;
}

let types: string[] = [];

const ListTypes: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(['common']);

  if (props.types) types = props.types.split(', ');

  return (
    <List>
      <ListItem
        className={`item-crudi ${
          types.includes(
            'Opções Crudiveganas (Cruas a no máximo com cocção até 40o)'
          )
            ? 'active'
            : ''
        }`}
      >
        {t('common:types.0.label')}
      </ListItem>

      <ListItem
        className={`item-vegan ${
          types.includes('Opções Veganas') ? 'active' : ''
        }`}
      >
        {t('common:types.1.label')}
      </ListItem>

      <ListItem
        className={`item-veg  ${
          types.includes('Opções Vegetarianas (Ovolacto)') ? 'active' : ''
        }`}
      >
        {t('common:types.2.label')}
      </ListItem>

      <ListItem
        className={`item-others ${
          types.includes('Opções com carne') ? 'active' : ''
        }`}
      >
        {t('common:types.3.label')}
      </ListItem>
    </List>
  );
};

export default ListTypes;
