import React, { useState, useEffect } from 'react';
import { withTheme, DefaultTheme } from 'styled-components';

import { EstablishmentProps } from '../../../Types';

import IconCar from 'res/svg/icon-car.svg';
import IconLink from 'res/svg/icon-link.svg';
import IconClose from 'res/svg/icon-close.svg';

import { ButtonIcon } from 'components';

import { Header, ListHeader, ListItemHeader } from './Header.styled';

interface Props {
  id?: string;
  theme?: DefaultTheme;
  establishmentData?: EstablishmentProps;
  isLoadingRoute?: boolean;
  className?: string;
  onClose?: () => void;
  onClickRoute?: (position?: string) => void;
}

const HeaderDetails: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.isLoadingRoute) {
      setLoading(false);
    }
  }, [props.isLoadingRoute, loading]);

  return (
    <Header id={props.id} className={props.className}>
      <ListHeader>
        <ListItemHeader>
          <ButtonIcon
            isLoading={props.isLoadingRoute}
            name={`${loading ? 'is-loading' : ''}`}
            onClick={() => {
              setLoading(true);
              props.onClickRoute &&
                props.onClickRoute(
                  props.establishmentData?.gsx$geolocalização?.$t
                );
            }}
            icon={IconCar}
          />
        </ListItemHeader>

        {props.establishmentData?.gsx$website?.$t && (
          <ListItemHeader>
            <ButtonIcon
              type={'anchor'}
              href={props.establishmentData?.gsx$website?.$t}
              target={'_blank'}
              rel={'noopener noreferrer'}
              icon={IconLink}
              backgroundColor={props.theme?.colors.background}
            />
          </ListItemHeader>
        )}

        <ListItemHeader>
          <ButtonIcon onClick={props.onClose} icon={IconClose} />
        </ListItemHeader>
      </ListHeader>
    </Header>
  );
};

export default withTheme(HeaderDetails);
