import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  isOpenList?: boolean;
  isOpenModal?: boolean;
  isOpenAside?: boolean;
}

export const IconAsset = styled.img``;

export const Icon = styled.span`
  position: absolute;
  margin-top: 0.5rem;
  left: 13px;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out 0s;

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin-top: 0.1rem;
  }
`;

export const ButtonClear = styled.button.attrs({ type: 'button' })`
  position: absolute;
  right: 33px;
  padding: 0.4rem 1.3rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.25s ease-in-out 0s;

  span {
    position: relative;
    left: auto;
  }

  &:hover {
    span {
      opacity: 0.6;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    right: 10px;
  }
`;

export const Form = styled.form<Props>`
  background-color: ${({ theme: { colors } }) => colors.background};

  display: flex;
  flex: 1;
  align-items: center;
  position: fixed;
  top: 0;
  left: 70px;
  z-index: 1;
  width: calc(100% - 7rem);
  height: 7.9rem;
  border-bottom: 0.5rem;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme: { colors } }) => colors.primary};
  transition: left 0.42s cubic-bezier(0.44, 0, 0.2, 1),
    width 0.42s cubic-bezier(0.44, 0, 0.2, 1);
  overflow: hidden;

  ${({ isOpenList }) =>
    isOpenList &&
    css`
      left: 60rem;
      width: calc(100% - 60rem);
    `}

  ${({ isOpenModal }) =>
    isOpenModal &&
    css`
      left: 67.6rem;
      width: calc(100% - 67.6rem);
    `}

  ${({ isOpenModal, isOpenList }) =>
    isOpenModal &&
    isOpenList &&
    css`
      width: calc(100% - 120.6rem);
      left: 120.6rem;
    `}

  ${({ isOpenAside }) =>
    isOpenAside &&
    css`
      left: 44.5rem;
      width: calc(100% - 44.5rem);
    `}

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        left: 67.6rem;
        width: calc(100% - 67.6rem);
      `}
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    position: fixed;
    top: 0;
    left: 70px;
    width: calc(100% - 7rem);

    &.hide {
      display: none !important;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    height: 6.9rem;
  }
`;

export const Input = styled.input.attrs({ type: 'text' })<
  HTMLAttributes<HTMLInputElement>
>`
  font-family: ${({ theme: { typography } }) => typography.body1.fontSize};

  margin-top: 0.5rem;
  padding-right: 9rem;
  padding-left: 4rem;
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  background-color: transparent;

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.secondaryAlpha40};
  }

  &:focus {
    + ${ButtonClear} {
      opacity: 1;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin-top: 0.1rem;
    padding-right: 5rem;
  }
`;
