import styled from 'styled-components';

export const Container = styled.p`
  font-size: ${({ theme: { typography } }) => typography.h2Mobile.fontSize};
  color: ${({ theme: { colors } }) => colors.onSecondary};

  margin-bottom: 4rem;
`;

export const Label = styled.span`
  font-family: ${({ theme: { typography } }) => typography.body3.fontFamily};
`;
