import React from 'react';
import { useTranslation } from 'react-i18next';

import { Center, H1, H2, Socials } from 'components';
import { Container, HelpText, Copy } from './SectionFooter.styled';

const SectionFooter: React.FC = () => {
  const { t } = useTranslation(['common', 'sectionFooter']);

  return (
    <Container>
      <Center>
        <H1 dangerouslySetInnerHTML={{ __html: t('sectionFooter:title') }} />
        <H2>{t('sectionFooter:subtitle')}</H2>
        <Socials />
        <HelpText
          dangerouslySetInnerHTML={{ __html: t('sectionFooter:help') }}
        />
        <Copy dangerouslySetInnerHTML={{ __html: t('sectionFooter:social') }} />
      </Center>
    </Container>
  );
};

export default SectionFooter;
