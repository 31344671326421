import styled from 'styled-components';

export const Container = styled.nav`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding: 3rem;
  display: none;
  pointer-events: none;

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    &.hide {
      display: none !important;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: block;
  }
`;

export const ListItem = styled.li`
  transition: transform 0.25s cubic-bezier(0.44, 0, 0.2, 1),
    visibility 0.25s cubic-bezier(0.44, 0, 0.2, 1),
    opacity 0.25s cubic-bezier(0.44, 0, 0.2, 1);

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  button {
    transition: transform 0.25s cubic-bezier(0.44, 0, 0.2, 1);
    pointer-events: auto;

    img {
      opacity: 1 !important;
    }

    &.close {
      transform: rotate(45deg);
    }

    &.open {
      transform: rotate(180deg);
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;

  ${ListItem} {
    &:nth-child(1) {
      opacity: 1;
      transform: translateY(calc(400% + 8rem));

      img {
        width: 1.4rem;
      }
    }

    &:nth-child(2) {
      opacity: 1;
      transform: translateY(calc(300% + 6rem));

      img {
        width: 1.4rem;
        height: 2.4rem;
      }
    }

    &:nth-child(3) {
      opacity: 1;
      transform: translateY(calc(200% + 4rem));

      img {
        width: 1.4rem;
        height: 1.8rem;
      }
    }

    &:nth-child(4) {
      opacity: 1;
      transform: translateY(calc(100% + 2rem));
    }
  }

  &.is-open {
    ${ListItem} {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`;
