export const defaultLocale = 'pt-BR';

export const supportedLocales = {
  'pt-BR': {
    common: require('./locales/pt-BR/common.json'),
    home: require('./locales/pt-BR/home.json'),
    mailchimp: require('./locales/pt-BR/mailchimp.json'),
    establishments: require('./locales/pt-BR/establishments.json'),
    sectionHeader: require('./locales/pt-BR/sectionHeader.json'),
    sectionFilter: require('./locales/pt-BR/sectionFilter.json'),
    sectionNotFind: require('./locales/pt-BR/sectionNotFind.json'),
    sectionCta: require('./locales/pt-BR/sectionCta.json'),
    sectionFooter: require('./locales/pt-BR/sectionFooter.json'),
  },
  'en-US': {
    common: require('./locales/en-US/common.json'),
    home: require('./locales/en-US/home.json'),
  },
};
