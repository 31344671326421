import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTheme, DefaultTheme } from 'styled-components';

import IconPin from 'res/svg/icon-menu-pin-dark.svg';
import IconCellphone from 'res/svg/icon-cellphone-dark.svg';
import IconList from 'res/svg/icon-menu-dark.svg';
import IconLogout from 'res/svg/icon-logout.svg';
import IconClose from 'res/svg/icon-close-light.svg';

import { ButtonIcon } from 'components';

import { Container, List, ListItem } from './MenuMobile.styled';

interface Props {
  theme: DefaultTheme;
  className?: string;
  openList?: boolean;
  onClickList?: () => void;
  onClickAsideApp?: () => void;
  onClickAsideRegister?: () => void;
}

const MenuMobile: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState(false);

  const history = useHistory();

  function handleClickHome() {
    history.push('/');
  }

  return (
    <Container className={props.className}>
      <List className={open ? 'is-open' : ''}>
        <ListItem>
          <ButtonIcon onClick={handleClickHome} icon={IconLogout} />
        </ListItem>

        {/* Aside App */}
        <ListItem>
          <ButtonIcon
            onClick={() => {
              props.onClickAsideApp && props.onClickAsideApp();
              setOpen(!open);
            }}
            icon={IconCellphone}
          />
        </ListItem>

        {/* Aside Register */}
        <ListItem>
          <ButtonIcon
            onClick={() => {
              props.onClickAsideRegister && props.onClickAsideRegister();
              setOpen(!open);
            }}
            icon={IconPin}
          />
        </ListItem>

        {/* List */}
        <ListItem>
          <ButtonIcon
            onClick={() => {
              props.onClickList && props.onClickList();
              setOpen(!open);
            }}
            icon={IconList}
          />
        </ListItem>

        {/* Close */}
        <ListItem>
          <ButtonIcon
            name={open ? 'open' : 'close'}
            icon={IconClose}
            backgroundColor={props.theme.colors.primary}
            onClick={() => setOpen(!open)}
          />
        </ListItem>
      </List>
    </Container>
  );
};

export default withTheme(MenuMobile);
