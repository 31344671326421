import styled from 'styled-components';

export const Layout = styled.div`
  background-color: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  margin: 0 auto;
  max-width: 1086px;
  width: 100%;

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    padding: 0 4rem;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding: 0 2rem;
  }
`;
