import React from 'react';
import { useTranslation } from 'react-i18next';

import IconSearch from 'res/svg/icon-search.svg';
import IconClean from 'res/svg/icon-close.svg';

import { Form, Input, Icon, IconAsset, ButtonClear } from './Search.styled';

interface Props {
  className?: string;
  isOpenList?: boolean;
  isOpenModal?: boolean;
  isOpenAside?: boolean;
}

const Search: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(['establishments']);

  function handleClickButtonClear() {
    const input = document.getElementById(
      'searchTextField'
    ) as HTMLInputElement;

    if (input) input.value = '';
  }

  return (
    <Form
      className={props.className}
      isOpenList={props.isOpenList}
      isOpenModal={props.isOpenModal}
      isOpenAside={props.isOpenAside}
    >
      <Icon>
        <IconAsset src={IconSearch} />
      </Icon>
      <Input
        id={'searchTextField'}
        placeholder={t('placeholder.search')}
        autoComplete={'off'}
      />
      <ButtonClear onClick={handleClickButtonClear}>
        <Icon>
          <IconAsset src={IconClean} />
        </Icon>
      </ButtonClear>
    </Form>
  );
};

export default Search;
