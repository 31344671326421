import React, { useState, FormEvent } from 'react';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CircleSpinner } from 'react-spinners-kit';

import { FormDataProps } from '../MailchimpForm';

interface Props {
  theme: DefaultTheme;
  status: string;
  message: string;
  className: string;
  onSubmitted: (formData: FormDataProps) => void;
}

interface SubmitProps {
  loading?: string;
}

const LabelButton = styled.span<SubmitProps>`
  opacity: ${({ loading }) => (loading === 'sending' ? 0 : 1)};
`;

const SimpleForm: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(['sectionCta', 'mailchimp']);
  const [empty, setEmpty] = useState(false);
  const [fieldEmail, setFieldEmail] = useState('');

  const submit = (e: FormEvent) => {
    e.preventDefault();

    if (fieldEmail.length === 0) {
      return setEmpty(true);
    }

    setEmpty(false);
    fieldEmail.indexOf('@') > -1 && props.onSubmitted({ EMAIL: fieldEmail });
  };

  return (
    <>
      <form onSubmit={submit} className={props.className}>
        <input
          type={'email'}
          placeholder={t('form.emailPlaceholder')}
          onChange={e => setFieldEmail(e.target.value)}
        />
        <button type={'submit'} onClick={submit}>
          <LabelButton loading={props.status}>
            {t('common:button.iWantToKnow')}
          </LabelButton>
          {props.status === 'sending' && (
            <CircleSpinner color={props.theme.colors.primary} size={20} />
          )}
        </button>
        <div className={'msg-alert'}>
          {empty && (
            <p
              dangerouslySetInnerHTML={{ __html: `${t('mailchimp:empty')}` }}
            />
          )}
          {props.status === 'error' && (
            <p
              className={'error'}
              dangerouslySetInnerHTML={{ __html: props.message }}
            />
          )}
          {props.status === 'success' && (
            <p
              className={'success'}
              dangerouslySetInnerHTML={{ __html: props.message }}
            />
          )}
        </div>
      </form>
    </>
  );
};

export default withTheme(SimpleForm);
