import styled from 'styled-components';

export const Col = styled.div``;

export const Icon = styled.div``;

export const IconAsset = styled.img`
  transition: top 0.35s cubic-bezier(0.19, 1, 0.22, 1);
`;

export const Label = styled.span`
  color: ${({ theme: { colors } }) => colors.primary};
`;

export const ImageLogo = styled.img`
  margin-bottom: 5.3rem;

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: none;
  }
`;

export const ImageLogoMobile = styled.img`
  display: none;
  margin: 0 auto 2.2rem auto;

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: block;
  }
`;

export const Container = styled.section`
  display: flex;
  align-items: center;
  padding: 9rem 0 4.5rem 0;
  min-height: 655px;
  height: calc(100vh - 15rem);

  a {
    text-decoration: none;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  h1 {
    margin-bottom: 6.6rem;
    max-width: 770px;
  }

  h2 {
    margin-bottom: 8.7rem;
    max-width: 680px;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    position: relative;
    padding-top: 0;
    min-height: auto;
    height: 100vh;

    h1,
    h2 {
      text-align: center;
    }

    h1 {
      margin-bottom: 2rem;
    }

    h2 {
      margin-bottom: 3.7rem;
    }
  }

  @media (max-width: 767px) and (max-height: 666px) and (orientation: portrait) {
    padding-top: 5.1rem;
    height: auto;
  }

  @media (max-width: 812px) and (max-height: 735px) and (orientation: landscape) {
    padding-top: 5.1rem;
    height: auto;
  }
`;

export const Button = styled.button.attrs({ type: 'button' })`
  font-family: ${({ theme: { typography } }) => typography.button1.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: opacity 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;

  ${Icon} {
    background-color: ${({ theme: { colors } }) => colors.primary};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 2.5rem;
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 5rem;

    img {
      position: relative;
      width: 2.4rem;
      transform: rotate(90deg);
    }

    .over {
      top: -32px;
    }

    .normal {
      top: -5px;
    }
  }

  &:active,
  &:focus {
    opacity: 1;
  }

  &:hover {
    .normal {
      top: 32px;
    }

    .over {
      top: 4px;
      transition-delay: 0.1s;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    flex-direction: column;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;

    ${Icon} {
      margin: 1.6rem 0 0 0;
    }

    > div {
      order: 2;
    }
  }

  @media (max-width: 767px) and (max-height: 666px) {
    position: relative;
    top: 0;
    margin-top: 3.7rem;
  }
`;
