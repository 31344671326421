import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from 'components';
import {
  SectionHeader,
  SectionCta,
  SectionFilter,
  SectionNotFind,
  SectionFooter,
} from '../components';

const HomeLayout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SectionHeader />
      <SectionFilter />
      <SectionCta
        title={t('sectionCta:title')}
        subtitle={t('sectionCta:subtitle')}
      />
      <SectionNotFind
        title={t('sectionNotFind:title')}
        subtitle={t('sectionNotFind:subtitle')}
      />
      <SectionFooter />
    </Layout>
  );
};

export default HomeLayout;
