import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.tertiary};
  position: relative;
`;

export const Iframe = styled.iframe`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: none;
  }
`;

export const Loading = styled.p`
  font-family: ${({ theme: { typography } }) => typography.button1.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  color: ${({ theme: { colors } }) => colors.primary};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  white-space: nowrap;
`;
