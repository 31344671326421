import styled from 'styled-components';

export const IconAsset = styled.img`
  position: relative;
  top: 9px;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;

  .typical-cuisine {
    color: ${({ theme: { colors } }) => colors.onSecondary};
    font-size: ${({ theme: { typography } }) => typography.button2.fontSize};

    margin: 0;
    line-height: 2.4rem;

    span {
      font-family: ${({ theme: { typography } }) =>
        typography.body3.fontFamily};
      display: inline-block;
    }
  }

  .list-phones {
    margin-top: 1rem;
  }
`;

export const Icon = styled.div`
  margin-right: 2.1rem;

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 2.4rem;
  width: 100%;
`;

export const ButtonName = styled.button.attrs({ type: 'button' })`
  color: ${({ theme: { colors } }) => colors.secondary};
  font-size: ${({ theme: { typography } }) => typography.h2Mobile.fontSize};
  font-family: ${({ theme: { typography } }) =>
    typography.subtitle1.fontFamily};

  position: relative;
  top: -4px;
  max-width: 285px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  line-height: 2.4rem;
  transition: color 0.25s ease-in-out 0s;
  cursor: pointer;

  &.active {
    color: ${({ theme: { colors } }) => colors.primary} !important;
  }

  &:focus {
    opacity: 1;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    max-width: 100%;
    width: calc(100% - 2rem);
  }
`;

export const Address = styled.address`
  color: ${({ theme: { colors } }) => colors.onSecondary};
  font-size: ${({ theme: { typography } }) => typography.button2.fontSize};

  margin: 0;
  max-width: 420px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  font-style: normal;
  line-height: 2.4rem;

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    max-width: 300px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;

    ${ButtonName} {
      color: ${({ theme: { colors } }) => colors.onSecondary};
    }
  }
`;

export const HeaderItem = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CtaList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CtaListItem = styled.li`
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;
