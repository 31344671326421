export default function maskPhone(phones: string) {
  const numbers = phones.split('/');

  const arrNumbers = numbers.map(number => {
    return number
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
  });

  return arrNumbers;
}
