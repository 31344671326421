import React from 'react';

import { Container } from './ContainerAside.styled';

interface Props {
  id?: string;
  children: React.ReactNode;
  show?: boolean;
  backgroundColor?: string;
}

const ContainerAside: React.FC<Props> = (props: Props) => (
  <Container
    id={props.id}
    className={props.show ? 'show' : 'hide'}
    backgroundColor={props.backgroundColor}
  >
    {props.children}
  </Container>
);

export default ContainerAside;
