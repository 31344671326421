import { combineReducers } from 'redux';
import { persistReducer, WebStorage } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { settingsReducer } from './settings/reducers';

const persistSettingsReducer = (storage: WebStorage) => {
  return persistReducer(
    {
      storage,
      key: 'settings',
      keyPrefix: '',
    },
    settingsReducer
  );
};

export const rootReducer = combineReducers({
  settings: persistSettingsReducer(storage),
});

export type ApplicationState = ReturnType<typeof rootReducer>;
