import React from 'react';
import styled, { css } from 'styled-components';

import { Layout } from 'components';

interface ListProps {
  toggle?: boolean;
  isOpenList?: boolean;
  isOpenModal?: boolean;
  isOpenListMobile?: boolean;
}

interface CloseList {
  show?: boolean;
}

interface InputProps {
  hasSearch: boolean;
}

export const Container = styled(props => <Layout {...props} />)<ListProps>`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .tooltip {
    transition: visibility 0.25s ease-in-out 0s;
    visibility: hidden;
  }

  #header-details {
    transform: translateX(calc(-100% - 7rem));
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    #header-details {
      left: auto;
      right: 0;
      max-width: calc(100% - 7rem);
      transform: translateX(calc(-100% - 7rem));
    }

    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        #header-details {
          transform: translateX(0);
        }
      `}
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    #header-details {
      max-width: 100%;
      transform: translateX(-100%);
    }

    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        #header-details {
          transform: translateX(0);
        }
      `}
  }
`;

export const ContainerMap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
`;

export const Map = styled.div<ListProps>`
  position: relative;
  width: inherit;
  height: inherit;
  transition: width 0.50s cubic-bezier(0.44, 0, 0.2, 1) 0.040s, visibility 0.1s 0.040s;

  ${({ isOpenList }) =>
    isOpenList &&
    css`
      width: calc(100% - 60rem);
    `}

  ${({ isOpenModal }) =>
    isOpenModal &&
    css`
      width: calc(100% - 60.6rem);
    `}

  ${({ isOpenModal, isOpenList }) =>
    isOpenModal &&
    isOpenList &&
    css`
      width: calc(100% - 120.6rem);
    `}

  .gm-style-iw-d {
    width: 29.5rem;
    overflow-x: hidden !important;
    overflow-y: visible;
  }

  .gm-style-iw-t {
    position: relative;
    opacity: 0;
    transform: translateY(25px);
    transition: opacity 0.42s cubic-bezier(0.44, 0, 0.2, 1) 0.2s, transform 0.42s cubic-bezier(0.44, 0, 0.2, 1) 0.2s;

    &.active {
      opacity: 1;
      transform: translateY(0);

      .tooltip {
        visibility: visible !important;
      }
    }

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      top: 18px;
      left: 0;
      height: 0;
      width: 0;
      border: solid transparent;
      pointer-events: none;
    }

    &:before {
      border-color: rgba(255, 87, 0, 0);
      border-top-color: ${({ theme: { colors } }) => colors.primary};
      border-width: 1.3rem;
      margin-left: -1.3rem;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: ${({ theme: { colors } }) => colors.background};
      background-color: ${({ theme: { colors } }) => colors.background};
      border-width: 0.7rem;
      margin-left: 0;
    }
  }

  .gm-style-iw-c {
    background-color: ${({ theme: { colors } }) => colors.background};
    border-color: ${({ theme: { colors } }) => colors.primary};

    top: 20px !important;
    padding: 1.5rem !important;
    min-width: 310px !important;
    width: 31rem;
    min-height: 114px;
    height: 11.4rem;
    border-width: 0.2rem;
    border-style: solid;
    border-radius: 1rem;

    > button {
      display: none !important;
    }
  }

  .gm-style-iw-d {
    overflow: visible !important;
  }

  .tooltip-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.8rem;

    &__wrap {
      display: flex;
      flex-direction: row;

      button {
        display: inline-block;
        position: relative;
        top: -14px;
        left: 0;
        width: 5rem;

        &:focus {
          opacity: 1;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      width: calc(100% - 5.4rem);
    }

    &__image {
      display: inline-block;
      margin-right: 1.1rem;
      min-width: 59px;
      width: 5.9rem;
      height: 5.9rem;
      background-color: #efeff4;
      border-radius: 1rem;
      overflow: hidden;
    }

    &__title {
      font-family: ${({ theme: { typography } }) => typography.h1.fontFamily};
      font-size: ${({ theme: { typography } }) => typography.h2Mobile.fontSize};
      color: ${({ theme: { colors } }) => colors.secondary};
      margin: 0 0 0.5rem 0;
      display: inline-block;
      font-weight: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__subtitle {
      font-family: ${({ theme: { typography } }) => typography.h2.fontFamily};
      font-size: ${({ theme: { typography } }) =>
        typography.subtitle2.fontSize};
      color: ${({ theme: { colors } }) => colors.onSecondary};
      margin: 0;
      font-weight: normal;
    }
  }

  .list-type {
    display: flex;
    align-items: center;
    margin-top: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 0.1pt;

    &__item {
      color: ${({ theme: { colors } }) => colors.onSecondary};
      font-family: ${({ theme: { typography } }) =>
        typography.body3.fontFamily};
      font-size: ${({ theme: { typography } }) => typography.body3.fontSize};

      display: flex;
      flex-direction: row;
      align-items: center;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:before {
        content: '';
        position: relative;
        top: -2px;
        display: inline-block;
        margin-right: 0.5rem;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 5rem;
        border-width: 0.2rem;
        border-style: solid;
      }

      &.Crudi {
        &:before {
          border-color: ${({ theme: { colors } }) => colors.crudi};
        }

        &.active {
          &:before {
            background-color: ${({ theme: { colors } }) => colors.crudi};
          }
        }
      }

      &.Vegan {
        &:before {
          border-color: ${({ theme: { colors } }) => colors.vegan};
        }

        &.active {
          &:before {
            background-color: ${({ theme: { colors } }) => colors.vegan};
          }
        }
      }

      &.Veg {
        &:before {
          border-color: ${({ theme: { colors } }) => colors.veg};
        }

        &.active {
          &:before {
            background-color: ${({ theme: { colors } }) => colors.veg};
          }
        }
      }

      &.Outros {
        &:before {
          border-color: ${({ theme: { colors } }) => colors.others};
        }

        &.active {
          &:before {
            background-color: ${({ theme: { colors } }) => colors.others};
          }
        }
      }
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        width: calc(100% - 67.5rem);
      `}
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    transition: none;
    width: calc(100% - 7rem);

    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        width: calc(100% - 7rem);
      `}

    ${({ isOpenList }) =>
      isOpenList &&
      css`
        width: calc(100% - 7rem);
      `}
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    width: 100%;

    &.hide {
      visibility: hidden;

      .gm-style-iw-c {
        opacity: 0;
      }
    }

    .gm-style-iw-c {
      min-width: 310px !important;
    }
  }
`;

export const Icon = styled.div``;

export const IconAsset = styled.img`
  position: relative;
  left: 15px;
`;

export const FormSearch = styled.form<ListProps>`
  border: 0;
  border-bottom-width: 0.5rem;
  border-bottom-style: solid;
  background-color: ${({ theme: { colors } }) => colors.background};
  border-bottom-color: ${({ theme: { colors } }) => colors.tertiary};

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 70px;
  z-index: 4;
  width: 53rem;
  min-width: 530px;
  height: 7.9rem;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 0.42s cubic-bezier(0.44, 0, 0.2, 1) 0.1s;

  ${({ toggle }) =>
    toggle
      ? css`
          transform: translateX(0);
        `
      : css`
          transform: translateX(-100%);
        `}

  img {
    position: relative;
    top: 5px;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    &.hide {
      display: none;
    }

    ${({ isOpenListMobile }) =>
      isOpenListMobile
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `}

    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        transform: translateX(-100%);
      `}
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    min-width: calc(100% - 7rem);
    width: 100%;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    left: 0;
    height: 6.9rem;

    ${({ isOpenListMobile }) =>
      isOpenListMobile &&
      css`
        transform: translateX(0);
      `}
  }
`;

export const ButtonClear = styled.button.attrs({ type: 'button' })`
  position: absolute;
  margin-top: 0.5rem;
  right: 10px;
  width: 5rem;
  height: 4rem;
  opacity: 0;
  transition: opacity 0.25s ease-in-out 0s;

  img {
    top: 0;
    left: auto;
  }

  &:hover {
    opacity: 0.6 !important;
  }
`;

export const InputSearch = styled.input<InputProps>`
  color: ${({ theme: { colors } }) => colors.secondary};

  margin-top: 0.5rem;
  padding: 0 8rem 0 2.8rem;
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.secondaryAlpha40};
  }

  &:focus {
    + ${ButtonClear} {
      opacity: 1;
    }
  }

  ${props =>
    props.hasSearch &&
    css`
      + ${ButtonClear} {
        opacity: 1;
      }
    `}

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    padding-right: 13.5rem;


    + ${ButtonClear} {
      right: 75px;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding-right: 7.5rem;

    + ${ButtonClear} {
      right: 10px;
    }
  }
`;

export const ContainerList = styled.ul<ListProps>`
  ${({ toggle }) =>
    toggle
      ? css`
          transform: translateX(0);
        `
      : css`
          transform: translateX(-100%);
        `}
  background-color: ${({ theme: { colors } }) => colors.background};

  position: absolute;
  top: 79px;
  left: 70px;
  z-index: 3;
  min-width: 530px;
  width: 53rem;
  height: calc(100vh - 7.9rem);
  transition: transform 0.42s cubic-bezier(0.44, 0, 0.2, 1) 0.1s;
  overflow: hidden;

  &.empty {
    height: calc(100vh - 7.9rem);
  }

  .ReactVirtualized__List {
    background-color: ${({ theme: { colors } }) => colors.background};

    padding: 3.5rem 0 1rem 2rem;
    direction: rtl !important;

    &:focus {
      outline: none;
    }
  }

  li {
    direction: ltr;
    text-align: left;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${({ isOpenListMobile }) =>
      isOpenListMobile
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `}

    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        transform: translateX(-100%);
      `}
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    z-index: 3;
    min-width: calc(100% - 7rem);
    overflow: visible;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    top: 69px;
    left: 0;
    min-width: 100%;
    width: 100%;
    height: auto;
    transform: translateX(-100%);

    &.empty {
      height: calc(100vh - 6.9rem);
    }

    &.list-filter {
      height: calc(100vh - 6.9rem);

      address {
        max-width: 100%;
      }
    }

    .ReactVirtualized__List {
      padding-top: 0;
    }

    ${({ isOpenListMobile }) =>
      isOpenListMobile &&
      css`
        transform: translateX(0);
      `}
  }
`;

export const ContainerDetails = styled.div<ListProps>`
  background-color: ${({ theme: { colors } }) => colors.tertiary};

  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 60.6rem;
  min-width: 606px;
  height: 100vh;
  direction: rtl;
  transform: translateX(-100%);
  transition: transform 0.42s cubic-bezier(0.44, 0, 0.2, 1);
  overflow: hidden;

  &.show {
    overflow: auto;
  }

  ${({ isOpenList }) =>
    isOpenList &&
    css`
      transform: translateX(-100%);
    `}

  ${({ isOpenModal }) =>
    isOpenModal &&
    css`
      transform: translateX(99%);
    `}

  ${({ isOpenList, isOpenModal }) =>
    !isOpenList &&
    isOpenModal &&
    css`
      transform: translateX(7rem);
    `}

  > div {
    direction: ltr;
    text-align: left;
    height: 100%;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${({ isOpenList }) =>
      isOpenList &&
      css`
        transform: translateX(-100%);
      `}

    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        transform: translateX(7rem);
        z-index: 5;
      `}
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    min-width: calc(100% - 7rem);

    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        transform: translateX(7rem);
      `}
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    min-width: 100%;
    width: 100%;
    height: auto;
    transform: translateX(-100%);

    ${({ isOpenModal }) =>
      isOpenModal &&
      css`
        transform: translateX(0);
      `}
  }
`;

export const Clusterer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.primary};
  display: inline-block;
  width: 4rem;
  height: 4rem;
  border-radius: 5rem;
`;

export const LoaderMap = styled.div`
  background-color: ${({ theme: { colors } }) => colors.backgroundAlpha75};

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;

  &.off {
    opacity: 0;
    visibility: hidden;

    div {
      animation: none;
    }
  }
`;

export const WrapButtonCloseMobileList = styled.div<CloseList>`
  position: fixed;
  bottom: -50%;
  right: 30px;
  z-index: 5;
  visibility: hidden;
  display: none;
  transition: all 0.55s cubic-bezier(0.44, 0, 0.2, 1);

  button {
    img {
      opacity: 1 !important;
    }
  }

  &.up {
    visibility: visible;
    bottom: 30px;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: block;
  }
`;
