import { Typography } from './theme';

const primaryFont = 'Raleway-Regular';
const secondaryFont = 'Raleway-Semibold';
const tertiaryFont = 'Raleway-Bold';

const typography: Typography = {
  h1: {
    fontFamily: tertiaryFont,
    fontSize: '3rem',
  },
  h1Mobile: {
    fontSize: '2.5rem',
  },
  h2: {
    fontFamily: primaryFont,
    fontSize: '2rem',
  },
  h2Mobile: {
    fontSize: '1.8rem',
  },
  subtitle1: {
    fontFamily: tertiaryFont,
    fontSize: '2.2rem',
  },
  subtitle2: {
    fontFamily: primaryFont,
    fontSize: '1.5rem',
  },
  body1: {
    fontFamily: primaryFont,
    fontSize: '2rem',
  },
  body2: {
    fontFamily: secondaryFont,
    fontSize: '2rem',
  },
  body3: {
    fontFamily: tertiaryFont,
    fontSize: '1.2rem',
  },
  button1: {
    fontFamily: secondaryFont,
    fontSize: '1.6rem',
  },
  button2: {
    fontFamily: tertiaryFont,
    fontSize: '1.4rem',
  },
  caption: {
    fontFamily: primaryFont,
    fontSize: '0.5rem',
  },
  overline: {
    fontFamily: primaryFont,
    fontSize: '0.4rem',
  },
};

export default typography;
