import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import ReactDOMServer from 'react-dom/server';
import oboe from 'oboe';
import MarkerClusterer from '@google/markerclusterer';
import {
  InfiniteLoader,
  AutoSizer,
  WindowScroller,
  List,
  Index,
  IndexRange,
} from 'react-virtualized';
import { debounce } from 'lodash';
import { DefaultTheme, withTheme } from 'styled-components';
import { CircleSpinner } from 'react-spinners-kit';
import { useTranslation } from 'react-i18next';

import IconPin from 'res/images/icon-pin.png';
import IconCluster from 'res/images/icon-cluster.png';
import IconFilter from 'res/svg/icon-filter-dark.svg';
import IconClose from 'res/svg/icon-close.svg';
import IconCloseLight from 'res/svg/icon-close-light.svg';

import HeaderDetails from './components/Details/components/Header';
import {
  ItemEstablishment,
  ButtonIcon,
  HeaderAside,
  ContainerAside,
} from 'components';
import {
  Menu,
  MenuMobile,
  Search,
  Tooltip,
  Details,
  EmptyList,
} from './components';

import { SPREADSHEET_ID, WORKSHEET_ID, MAP_STYLES } from './Variables';
import { EstablishmentProps, MarkerProps, Coords, RenderList } from './Types';

import SectionNotFind from '../../Home/components/SectionNotFind/SectionNotFind';
import SectionCta from '../../Home/components/SectionCta/SectionCta';

import {
  Container,
  Map,
  ContainerMap,
  ContainerList,
  ContainerDetails,
  FormSearch,
  InputSearch,
  Icon,
  IconAsset,
  ButtonClear,
  LoaderMap,
  WrapButtonCloseMobileList,
} from './Establishments.styled';

import 'react-virtualized/styles.css';

interface Props {
  theme: DefaultTheme;
  isOpenList?: boolean;
  isOpenModal?: boolean;
  isOpenListMobile?: boolean;
  isOpenAsideApp?: boolean;
  isOpenAsideRegister?: boolean;
  establishmentData?: EstablishmentProps;
  addEstablishmentToModal?: (establishmentData?: EstablishmentProps) => void;
  onClickList?: () => void;
  onClickListMobile?: () => void;
  onClickOpenModal?: () => void;
  onClickCloseModal?: () => void;
  onClickAsideApp?: () => void;
  onClickAsideRegister?: () => void;
}

let map: google.maps.Map | undefined;
let infoBox: google.maps.InfoWindow | undefined;
let autocomplete: google.maps.places.Autocomplete | undefined;
let activeButtonRouter: HTMLButtonElement | undefined;
let itemRouteActive: HTMLUListElement | undefined;
let markerClusterer: MarkerClustererOptions | undefined;
let data: EstablishmentProps[] = [];
let arrMarkers: MarkerProps[] = [];
let eventWatchPosition: number;
let itemActiveID = '';
let userPosition = '';
let destinyRoute = '';
const perPage = 20;

const EstablishmentsLayout: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const mapRef = useRef<HTMLDivElement>(null);
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const [actualData, setActualData] = useState<EstablishmentProps[]>([]);
  const [loadingRoute, setLoadingRoute] = useState(false);
  const [filter, setFilter] = useState(false);
  const [emptyFilter, setEmptyFilter] = useState(false);
  const [mapReady, setMapReady] = useState(false);
  const [hideMap, setHideMap] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showList, setShowList] = useState(false);

  const clusterStyles = [
    {
      width: 70,
      height: 70,
      fontFamily: `${props.theme.typography.h1}`,
      textSize: 18,
      textColor: 'white',
      url: IconCluster,
    },
  ];

  function fitAllMarkers() {
    const bounds = new window.google.maps.LatLngBounds();

    arrMarkers.map((marker: MarkerProps) => {
      const lat = marker?.getPosition()?.lat();
      const lng = marker?.getPosition()?.lng();

      if (lat && lng) {
        const position = new window.google.maps.LatLng(lat, lng);

        return bounds.extend(position);
      }

      return bounds;
    });

    if (infoBox) infoBox.close();
    map?.fitBounds(bounds);
    map?.panBy(0, -46);

    setTimeout(() => setMapReady(true), 425);
    // console.log(`::: On Map: ${arrMarkers.length} establishments visibles`);
    console.log(`::: List: ${data.length} establishments`);
  }

  function handleClickButtonClear() {
    const searchFilter = document.getElementById(
      'searchListField'
    ) as HTMLInputElement;

    searchFilter.value = '';

    const max = data.slice(0, perPage);

    setFilter(false);
    setActualData(max);
  }

  function toggleListItemActive(isActive: boolean) {
    itemActiveID = activeButtonRouter?.getAttribute('id') as string;

    if (isActive) {
      if (props.isOpenModal) {
        document
          .getElementById(itemActiveID)
          ?.querySelector('.button-description')
          ?.classList.add('active');
      }

      return document
        .getElementById(itemActiveID)
        ?.querySelector('.item-title')
        ?.classList.add('active');
    }

    document
      .getElementById(itemActiveID)
      ?.querySelector('.button-description')
      ?.classList.remove('active');
  }

  function handleClickMarker(marker: MarkerProps, fromList = false) {
    const { position } = marker;

    infoBox = new window.google.maps.InfoWindow({
      content: ReactDOMServer.renderToString(<Tooltip content={marker.data} />),
    });

    if (position) {
      if (fromList) {
        map?.panTo(position);
        map?.setZoom(18);
        map?.panBy(0, -114);
        window.google.maps.event.trigger(marker, 'mouseover');
      } else {
        map?.panTo(position);
        map?.panBy(0, -114);
      }
    }

    infoBox.open(map, marker);

    window.google.maps.event.addListener(infoBox, 'domready', () => {
      const tooltip = document.querySelector('.gm-style-iw-t');
      const btnMore = document.getElementById('btn-more');

      tooltip?.classList.add('active');

      btnMore?.addEventListener('click', () => {
        setTimeout(() => {
          setHideMap(true);
          setShowList(true);
        }, 425);
        props.onClickOpenModal && props.onClickOpenModal();
      });
    });
  }

  function createMarkers() {
    const markers = data.map((location: EstablishmentProps) => {
      const { gsx$geolocalização: geolocation } = location;
      const coord = geolocation?.$t?.split(', ');

      if (coord) {
        const lat = isNaN(Number(coord[0])) ? 0 : Number(coord[0]);
        const lng = isNaN(Number(coord[1])) ? 0 : Number(coord[1]);

        const position = { lat, lng };

        const marker: MarkerProps = new window.google.maps.Marker({
          position,
          icon: IconPin,
        });

        marker['data'] = location;

        marker.addListener('click', () => {
          if (infoBox) infoBox.close();

          handleClickMarker(marker);

          props.addEstablishmentToModal &&
            props.addEstablishmentToModal(marker.data);
        });

        arrMarkers.push(marker);
        return marker;
      }

      return null;
    });

    markerClusterer = new MarkerClusterer(map, markers, {
      maxZoom: 15,
      styles: clusterStyles,
    });

    if (markerClusterer) setTimeout(() => fitAllMarkers(), 425);
  }

  function handleClickListItem(
    establishmentData: EstablishmentProps,
    index?: number
  ) {
    if (infoBox) infoBox.close();

    const establishment = arrMarkers.filter(
      marker => establishmentData.id === marker.data?.id
    );

    handleClickMarker(establishment[0], true);

    props.onClickListMobile && props.onClickListMobile();

    activeButtonRouter = document.getElementById(
      `list-item-${index}`
    ) as HTMLButtonElement;

    const allActivesDetails = document.querySelectorAll(
      '.button-description.active'
    );
    const allTitlesActives = document.querySelectorAll('.item-title.active');

    for (let i = 0; i < allTitlesActives.length; ++i) {
      allTitlesActives[i].classList.remove('active');
    }

    for (let i = 0; i < allActivesDetails.length; ++i) {
      allActivesDetails[i].classList.remove('active');
    }

    activeButtonRouter?.querySelector('.item-title')?.classList.add('active');

    if (props.isOpenModal) {
      activeButtonRouter
        ?.querySelector('.button-description')
        ?.classList.add('active');
    }

    setHideMap(false);

    props.addEstablishmentToModal &&
      props.addEstablishmentToModal(establishment[0].data);
  }

  function handleClickOpenModal(
    establishmentData: EstablishmentProps,
    index?: number
  ) {
    setShowList(true);

    if (mapReady) {
      handleClickListItem(establishmentData);
    } else {
      const establishment = data.filter(
        establishment => establishmentData.id === establishment.id
      );

      props.addEstablishmentToModal &&
        props.addEstablishmentToModal(establishment[0]);
    }

    activeButtonRouter = document.getElementById(
      `list-item-${index}`
    ) as HTMLButtonElement;
    const elemBody = document.getElementById('body');
    elemBody?.classList.add('fixed');

    const allActives = document.querySelectorAll('.button-description.active');
    const allTitlesActives = document.querySelectorAll('.item-title.active');

    for (let i = 0; i < allActives.length; ++i) {
      allActives[i].classList.remove('active');
    }

    for (let i = 0; i < allActives.length; ++i) {
      allTitlesActives[i].classList.remove('active');
    }

    activeButtonRouter
      ?.querySelector('.button-description')
      ?.classList.add('active');

    activeButtonRouter?.querySelector('.item-title')?.classList.add('active');

    setTimeout(() => setHideMap(true), 425);
    return props.onClickOpenModal && props.onClickOpenModal();
  }

  function handleClickCloseModal() {
    if (activeButtonRouter) toggleListItemActive(false);
    props.onClickCloseModal && props.onClickCloseModal();

    setHideMap(false);
    setTimeout(() => setShowList(false), 450);

    const elemBody = document.getElementById('body');
    elemBody?.classList.remove('fixed');
  }

  function getData() {
    oboe(
      `https://spreadsheets.google.com/feeds/list/${SPREADSHEET_ID}/${WORKSHEET_ID}/public/values?alt=json`
    )
      .node('entry.*', (local: EstablishmentProps, path: []) => {
        const itemIndex = path[path.length - 1];

        if (local.gsx$geolocalização?.$t) {
          data.push(local);
        }

        if (itemIndex === perPage) {
          const max = data.slice(0, perPage);
          setActualData(max);
        }
      })
      .done(() => {
        createMarkers();
        // .done((list: object)) =>
        // console.log(`::: Found: ${list.feed.entry.length} establishments`);
      });
  }

  function isRowLoaded(params: Index) {
    const { index } = params;

    return !!actualData[index];
  }

  function loadMoreRows(params: IndexRange) {
    const { startIndex, stopIndex } = params;

    const dataLoaded: EstablishmentProps[] = [];

    return new Promise(() => {
      for (let i = startIndex; i < stopIndex; i++) {
        dataLoaded.push(data[i]);
      }

      setActualData(actualData => [...actualData, ...dataLoaded]);
    });
  }

  function openRoute() {
    setLoadingRoute(false);

    itemRouteActive
      ?.querySelector('.button-router')
      ?.classList.remove('is-loading');

    window.open(
      `https://www.google.com/maps/dir/${userPosition}/${destinyRoute}`,
      '_blank'
    );
  }

  function showPosition(geolocation: Coords) {
    const { latitude, longitude } = geolocation.coords;

    userPosition = `${latitude},${longitude}`;

    navigator.geolocation.clearWatch(eventWatchPosition);
    openRoute();
  }

  function getUserLocation() {
    if (navigator.geolocation) {
      eventWatchPosition = navigator.geolocation.watchPosition(
        showPosition,
        err => console.warn('ERROR(' + err.code + '): ' + err.message),
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      alert(
        "I'm sorry, but geolocation services are not supported by your browser."
      );
    }
  }

  function createRoute(establishmentPosition = '', listItemID?: number) {
    const destiny = establishmentPosition.replace(' ', '');

    destinyRoute = destiny;
    setLoadingRoute(true);

    itemRouteActive = document.getElementById(
      `list-item-${listItemID}`
    ) as HTMLUListElement;

    itemRouteActive
      ?.querySelector('.button-router')
      ?.classList.add('is-loading');

    if (userPosition) {
      return openRoute();
    }

    getUserLocation();
  }

  function renderListItem(params: RenderList) {
    const { index, key, style, isScrolling } = params;

    if (isScrolling && activeButtonRouter) {
      toggleListItemActive(true);
    }

    return (
      <ItemEstablishment
        id={`list-item-${index}`}
        key={key}
        style={style}
        establishmentData={actualData[index]}
        onClickItem={() => handleClickListItem(actualData[index], index)}
        onClickDescription={() =>
          handleClickOpenModal(actualData[index], index)
        }
        onClickRoute={() =>
          createRoute(actualData[index].gsx$geolocalização?.$t, index)
        }
        isLoadingRoute={loadingRoute}
      />
    );
  }

  function handleClickOnMap() {
    if (infoBox) {
      const tooltip = document.querySelector('.gm-style-iw-t');

      tooltip?.classList.remove('active');

      handleClickCloseModal();

      const allTitlesActives = document.querySelectorAll('.item-title.active');

      for (let i = 0; i < allTitlesActives.length; ++i) {
        allTitlesActives[i].classList.remove('active');
      }

      activeButtonRouter = undefined;

      setTimeout(() => {
        infoBox?.close();
      }, 450);
    }
  }

  function filterList(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    let debounceFilter = undefined;

    if (value.length > 2) {
      setFilter(true);

      debounceFilter = debounce(() => {
        const response = data.filter(establishment => {
          return establishment.gsx$nomedoestabelecimento?.$t
            ?.toLowerCase()
            .includes(value.toLowerCase());
        });

        if (response.length === 0) {
          const elemBody = document.getElementById('body');
          elemBody?.classList.add('fixed');

          return setEmptyFilter(true);
        }

        setEmptyFilter(false);
        setActualData(response);
      }, 425);

      return debounceFilter();
    }

    const max = data.slice(0, perPage);

    setFilter(false);
    setActualData(max);
  }

  useEffect(() => {
    document.getElementById('body')?.classList.add('block');

    function addAutoComplete() {
      const input = document.getElementById(
        'searchTextField'
      ) as HTMLInputElement;

      autocomplete = new window.google.maps.places.Autocomplete(input);

      if (map) autocomplete.bindTo('bounds', map);

      autocomplete.addListener('place_changed', () => {
        if (infoBox) infoBox.close();

        const place = autocomplete?.getPlace();

        if (!place?.geometry) {
          // window.alert(`No details available for input: ${name}`);
          return;
        }

        if (place.geometry.viewport) {
          return map?.fitBounds(place.geometry.viewport);
        }

        map?.setCenter(place.geometry.location);
        map?.setZoom(15);
      });

      window.google.maps.event.addDomListener(
        input,
        'keydown',
        (event: any) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }
      );
    }

    function createGoogleMap() {
      if (mapRef.current) {
        map = new window.google.maps.Map(mapRef.current, {
          zoom: 10,
          center: { lat: -23.5475, lng: -46.63611 },
          disableDefaultUI: true,
        });

        map.setOptions({ styles: MAP_STYLES });

        window.google.maps.event.addListener(map, 'click', handleClickOnMap);

        addAutoComplete();
        getData();
      }
    }

    function updateSize() {
      if (window.innerWidth < 1023) {
        return setIsMobile(true);
      }

      setIsMobile(false);
    }

    if (document.readyState === 'complete') {
      createGoogleMap();
    } else {
      window.addEventListener('load', createGoogleMap);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      const divAutocomplete = document.getElementsByClassName(
        'pac-container'
      ) as HTMLCollection;

      data = [];
      arrMarkers = [];
      map = undefined;
      infoBox = undefined;
      activeButtonRouter = undefined;
      itemRouteActive = undefined;
      itemActiveID = '';
      divAutocomplete[0].parentNode?.removeChild(divAutocomplete[0]);
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('load', createGoogleMap);
    };
  }, []);

  useEffect(() => {
    if (
      props.isOpenList ||
      props.isOpenModal ||
      props.isOpenAsideApp ||
      props.isOpenAsideRegister ||
      props.isOpenListMobile
    ) {
      return document.getElementById('body')?.classList.remove('block');
    }

    document.getElementById('body')?.classList.add('block');

    return () => {
      document.getElementById('body')?.classList.remove('block');
    };
  }, [
    props.isOpenList,
    props.isOpenModal,
    props.isOpenAsideApp,
    props.isOpenAsideRegister,
    props.isOpenListMobile,
  ]);

  return (
    <Container isOpenModal={props.isOpenModal}>
      <Search
        className={hideMap ? 'hide' : ''}
        isOpenList={props.isOpenList}
        isOpenModal={props.isOpenModal}
        isOpenAside={props.isOpenAsideRegister || props.isOpenAsideApp}
      />

      <Menu
        className={hideMap ? 'hide' : ''}
        onClickLogo={() => {
          if (mapReady) {
            fitAllMarkers();
          }
        }}
        onClickList={() => {
          props.onClickList && props.onClickList();
          if (props.isOpenList) {
            setTimeout(() => setShowList(false), 450);
          } else {
            setShowList(true);
          }
        }}
        openList={props.isOpenListMobile || props.isOpenList}
        openAsideApp={props.isOpenAsideApp}
        openAsideRegister={props.isOpenAsideRegister}
        onClickListMobile={props.onClickListMobile}
        onClickAsideApp={props.onClickAsideApp}
        onClickAsideRegister={props.onClickAsideRegister}
      />

      <MenuMobile
        className={hideMap ? 'hide' : ''}
        onClickList={() => {
          setTimeout(() => setHideMap(true), 425);
          props.onClickListMobile && props.onClickListMobile();
        }}
        onClickAsideApp={() => {
          setTimeout(() => setHideMap(true), 425);
          props.onClickAsideApp && props.onClickAsideApp();
        }}
        onClickAsideRegister={() => {
          setTimeout(() => setHideMap(true), 425);
          props.onClickAsideRegister && props.onClickAsideRegister();
        }}
      />

      <FormSearch
        onSubmit={event => event.preventDefault()}
        toggle={props.isOpenList}
        isOpenList={props.isOpenList}
        isOpenModal={props.isOpenModal}
        isOpenListMobile={props.isOpenListMobile}
      >
        <Icon>
          <IconAsset src={IconFilter} />
        </Icon>
        <InputSearch
          type={'text'}
          id={'searchListField'}
          placeholder={'Buscar por restaurantes'}
          onChange={filterList}
          autoComplete={'off'}
          hasSearch={filter}
        />
        <ButtonClear onClick={handleClickButtonClear}>
          <Icon>
            <IconAsset src={IconClose} />
          </Icon>
        </ButtonClear>
      </FormSearch>

      <ContainerList
        id={'container-list'}
        toggle={props.isOpenList}
        className={`${emptyFilter ? 'empty' : ''} ${
          filter ? 'list-filter' : ''
        } ${showList ? 'show' : ''}`}
        isOpenList={props.isOpenList}
        isOpenModal={props.isOpenModal}
        isOpenListMobile={props.isOpenListMobile}
      >
        {filter ? (
          <>
            {emptyFilter ? (
              <EmptyList />
            ) : (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    id={'list'}
                    width={width}
                    height={height}
                    rowHeight={210}
                    rowCount={actualData.length}
                    rowRenderer={renderListItem}
                  />
                )}
              </AutoSizer>
            )}
          </>
        ) : (
          <InfiniteLoader
            ref={infiniteLoaderRef}
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={data.length}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller>
                {({ height, scrollTop }) => (
                  <AutoSizer>
                    {({ width }) => (
                      <List
                        ref={registerChild}
                        autoHeight={isMobile}
                        id={'list'}
                        width={width}
                        height={height}
                        rowHeight={210}
                        rowCount={actualData.length}
                        rowRenderer={renderListItem}
                        onRowsRendered={onRowsRendered}
                        scrollTop={isMobile ? scrollTop : undefined}
                      />
                    )}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        )}
      </ContainerList>

      <WrapButtonCloseMobileList className={props.isOpenListMobile ? 'up' : ''}>
        <ButtonIcon
          onClick={() => {
            setHideMap(false);
            props.onClickListMobile && props.onClickListMobile();
          }}
          icon={IconCloseLight}
          backgroundColor={props.theme.colors.primary}
        />
      </WrapButtonCloseMobileList>

      <HeaderDetails
        id={'header-details'}
        className={props.isOpenModal ? 'show' : ''}
        isLoadingRoute={loadingRoute}
        establishmentData={props.establishmentData}
        onClose={handleClickCloseModal}
        onClickRoute={() => createRoute()}
      />

      <ContainerDetails
        id={'container-details'}
        className={`${showList ? 'show' : ''}`}
        isOpenList={props.isOpenList}
        isOpenModal={props.isOpenModal}
      >
        <Details
          isLoadingRoute={loadingRoute}
          establishmentData={props.establishmentData}
          onClickRoute={() => createRoute()}
          onClickClose={handleClickCloseModal}
        />
      </ContainerDetails>

      <HeaderAside
        variant={'primary'}
        className={props.isOpenAsideApp ? 'show' : ''}
        onClickClose={() => {
          setHideMap(false);
          props.onClickAsideApp && props.onClickAsideApp();
        }}
      />

      <ContainerAside
        id={'section-app'}
        show={props.isOpenAsideApp}
        backgroundColor={props.theme.colors.primary}
      >
        <SectionCta
          title={t('sectionCta:titleOpt2')}
          subtitle={t('sectionCta:subtitleOpt2')}
        />
      </ContainerAside>

      <HeaderAside
        className={props.isOpenAsideRegister ? 'show' : ''}
        onClickClose={() => {
          setHideMap(false);
          props.onClickAsideRegister && props.onClickAsideRegister();
        }}
      />

      <ContainerAside id={'section-register'} show={props.isOpenAsideRegister}>
        <SectionNotFind
          title={t('sectionNotFind:titleOpt2')}
          subtitle={t('sectionNotFind:subtitle')}
        />
      </ContainerAside>

      <ContainerMap>
        <LoaderMap className={mapReady ? 'off' : ''}>
          <CircleSpinner color={props.theme.colors.primary} size={20} />
        </LoaderMap>

        <Map
          ref={mapRef}
          className={hideMap ? 'hide' : ''}
          isOpenList={props.isOpenList}
          isOpenModal={props.isOpenModal}
        />
      </ContainerMap>
      <Tooltip />
    </Container>
  );
};

export default withTheme(EstablishmentsLayout);
