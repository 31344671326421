import styled from 'styled-components';

interface Props {
  backgroundColor?: string;
  isOpenAsideApp?: boolean;
  isOpenAsideRegister?: boolean;
}

export const Container = styled.aside<Props>`
  background-color: ${({ backgroundColor = '#ffffff' }) => backgroundColor};

  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  z-index: 4;
  padding: 0 2rem 0 1.7rem;
  max-width: 375px;
  width: 37.5rem;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
  transform: translateX(-100%);
  transition: transform 0.42s cubic-bezier(0.44, 0, 0.2, 1);

  &.show {
    transform: translateX(7rem);
    overflow-y: auto;
  }

  &.hide {
    overflow-y: hidden;
  }

  section {
    margin: 0 auto;
    max-width: 338px;
  }

  &#section-app {
    section {
      padding: 0;
      background-color: transparent;

      div {
        order: 2;
        display: flex;
        flex-direction: column;
        padding: 0;
        min-width: 100%;
        width: 100%;

        > div {
          position: relative;
          top: -70px;
          margin-bottom: 0;

          > div {
            top: 0;
            text-align: center;

            input {
              width: 100%;
              text-align: center;
            }

            button {
              margin-top: 2.3rem;
              width: 100%;
            }
          }
        }

        /* fix preloader */
        .circle__Wrapper-sc-16bbsoy-0 {
          min-width: 20px;
          width: 2rem;
        }

        .msg-alert {
          top: 0;
          margin-top: 1.5rem;
        }
      }

      img {
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        max-width: 800px;
      }
    }

    h1 {
      font-size: ${({ theme: { typography } }) => typography.h1Mobile.fontSize};

      margin-top: 0;
      margin-bottom: 3rem;
      line-height: 3rem;
    }

    h2 {
      font-size: ${({ theme: { typography } }) => typography.h2Mobile.fontSize};
      line-height: 2.6rem;
    }

    @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
      overflow: hidden;

      section {
        > div {
          top: 0;
          margin: 0;

          > div {
            margin-top: 0;
          }
        }
      }
    }
  }

  &#section-register {
    section {
      div {
        display: flex;
        flex-direction: column;
        width: 100%;

        > div:first-of-type {
          margin: 0;
          width: 39.5rem;
          height: 30.4rem;

          span {
            height: inherit;
          }
        }
      }

      h1 {
        font-size: ${({ theme: { typography } }) =>
          typography.h1Mobile.fontSize};

        margin-top: 9rem;
        margin-bottom: 3rem;
        line-height: 3rem;
      }

      h2 {
        font-size: ${({ theme: { typography } }) =>
          typography.h2Mobile.fontSize};
        line-height: 2.6rem;
      }
    }

    @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
      section {
        > div {
          padding: 0;
        }
      }
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    min-width: calc(100% - 7rem);
    width: 100%;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    min-width: 100%;
    min-width: 100%;
    width: 100%;
    height: auto;
    transform: translateX(-100%);

    &.show {
      transform: translateX(0);
    }
  }
`;
