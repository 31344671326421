import React from 'react';
import { withTheme, DefaultTheme } from 'styled-components';
import { CircleSpinner } from 'react-spinners-kit';

import {
  ContainerButton,
  Icon,
  IconAsset,
  ContainerAnchor,
  WrapLoader,
} from './ButtonIcon.styled';

interface Props {
  theme: DefaultTheme;
  type?: 'button' | 'anchor';
  name?: string;
  icon?: string;
  href?: string;
  target?: string;
  rel?: string;
  backgroundColor?: string;
  iconHover?: string;
  isLoading?: boolean;
  loadingColor?: string;
  className?: string;
  active?: boolean;
  onClick?: () => void;
}

const ButtonIcon: React.FC<Props> = (props: Props) => {
  const {
    type = 'button',
    icon,
    iconHover,
    name = '',
    backgroundColor,
    href,
    target,
    rel,
    active = false,
    isLoading,
    onClick,
  } = props;

  return (
    <>
      {type === 'button' && (
        <ContainerButton
          onClick={onClick}
          backgroundColor={backgroundColor}
          className={`${name}`}
          disabled={isLoading}
          active={active}
        >
          <WrapLoader>
            <CircleSpinner color={props.theme.colors.primary} size={20} />
          </WrapLoader>
          <Icon>
            <IconAsset src={icon} />
            {iconHover && <IconAsset src={iconHover} />}
          </Icon>
        </ContainerButton>
      )}
      {type === 'anchor' && (
        <ContainerAnchor
          target={target}
          href={href}
          rel={rel}
          backgroundColor={backgroundColor}
        >
          <Icon>
            <IconAsset src={icon} />
            {iconHover && <IconAsset src={iconHover} />}
          </Icon>
        </ContainerAnchor>
      )}
    </>
  );
};

export default withTheme(ButtonIcon);
