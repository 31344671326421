import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header``;

export const HeaderWrap = styled.div``;

export const HeaderInfo = styled.div``;

export const Image = styled.img``;

export const Title = styled.h1``;

export const Subtitle = styled.h2``;

export const ListType = styled.ul``;

export const ListTypeItem = styled.li``;

export const ButtonMore = styled.button.attrs({ type: 'button' })``;

export const IconAsset = styled.img``;
