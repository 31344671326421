import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0 1.8rem 0;
`;

export const ListItem = styled.li`
  color: ${({ theme: { colors } }) => colors.onSecondary};
  font-family: ${({ theme: { typography } }) => typography.body3.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.body3.fontSize};

  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.1pt;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:before {
    content: '';
    position: relative;
    top: -2px;
    display: inline-block;
    margin-right: 0.5rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 5rem;
    border-width: 0.2rem;
    border-style: solid;
  }

  &.item-crudi {
    &.active {
      &:before {
        border-color: ${({ theme: { colors } }) => colors.crudi};
        background-color: ${({ theme: { colors } }) => colors.crudi};
      }
    }
  }

  &.item-vegan {
    &.active {
      &:before {
        border-color: ${({ theme: { colors } }) => colors.vegan};
        background-color: ${({ theme: { colors } }) => colors.vegan};
      }
    }
  }

  &.item-veg {
    &.active {
      &:before {
        border-color: ${({ theme: { colors } }) => colors.veg};
        background-color: ${({ theme: { colors } }) => colors.veg};
      }
    }
  }

  &.item-others {
    &.active {
      &:before {
        border-color: ${({ theme: { colors } }) => colors.others};
        background-color: ${({ theme: { colors } }) => colors.others};
      }
    }
  }
`;
