import styled from 'styled-components';

import { H1 } from 'components';

export const Icon = styled.img``;

export const Container = styled.div`
  position: relative;
  width: 100%;

  @media ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    header {
      display: none;
    }
  }
`;

export const Content = styled.div`
  padding: 11.5rem 4rem 3.4rem 4rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  direction: rtl;

  > * {
    direction: ltr;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding-left: 2rem;
    padding-right: 2.6rem;
    overflow-y: hidden;
  }
`;

export const Title = styled(H1)`
  margin-bottom: 4rem;
  line-height: 3.4rem;
`;

export const ContentAddress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 4rem;

  img {
    margin-right: 3.2rem;
  }
`;

export const Address = styled.address`
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  color: ${({ theme: { colors } }) => colors.onSecondary};
  font-style: normal;
  line-height: 2.4rem;
`;

export const Subtitle = styled.h2`
  font-family: ${({ theme: { typography } }) => typography.body3.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.h2Mobile.fontSize};
  margin-top: 0;
  margin-bottom: 1.3rem;
`;

export const ContainerPhones = styled.div`
  margin-bottom: 3rem;
`;

export const ContainerProductTypes = styled.div`
  margin-bottom: 3rem;
`;

export const ListProductTypes = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ListItemProductTypes = styled.li`
  font-size: ${({ theme: { typography } }) => typography.body3.fontSize};
  font-family: ${({ theme: { typography } }) => typography.body2.fontFamily};
  color: ${({ theme: { colors } }) => colors.onSecondary};
  background-color: ${({ theme: { colors } }) => colors.onTertiary};

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
  padding: 0 1.3rem;
  height: 3.2rem;
  border-radius: 0.4rem;

  &:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

export const ContainerOpeningHours = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export const ListWeek = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
`;

export const ListItemWeek = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Day = styled.p`
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  font-family: ${({ theme: { typography } }) => typography.button1.fontFamily};
  color: ${({ theme: { colors } }) => colors.onSecondary};
  margin: 0;
`;

export const ListHours = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Hours = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

export const ListItemHours = styled.li`
  font-family: ${({ theme: { typography } }) => typography.body2.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  color: ${({ theme: { colors } }) => colors.onSecondary};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Start = styled.span`
  background-color: ${({ theme: { colors } }) => colors.onTertiary};
  font-size: ${({ theme: { typography } }) => typography.button2.fontSize};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.6rem;
  padding: 0 1.3rem;
  height: 3.1rem;
  border-radius: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
`;

export const End = styled(Start)`
  margin-right: 0;
  margin-left: 0.6rem;
`;

export const Closed = styled.span`
  color: ${({ theme: { colors } }) => colors.background};
  background-color: ${({ theme: { colors } }) => colors.error};
  font-family: ${({ theme: { typography } }) => typography.body2.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button2.fontSize};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.8rem;
  height: 3.1rem;
  border-radius: 1rem;
`;

export const ContainerSocialProfiles = styled.div``;

export const ListSocial = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const ListItemSocial = styled.li`
  height: 4.6rem;
`;

export const SocialAnchor = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  height: inherit;
`;

export const SocialIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 50%;
`;

export const SocialName = styled.span`
  font-family: ${({ theme: { typography } }) => typography.button2.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button2.fontSize};
  color: ${({ theme: { colors } }) => colors.onSecondary};
  margin-left: 1.6rem;
`;
