import React from 'react';
import { DefaultTheme, withTheme } from 'styled-components';

import IconClose from 'res/svg/icon-close.svg';
import IconCloseLight from 'res/svg/icon-close-light.svg';

import { ButtonIcon } from 'components';

import { Container } from './HeaderAside.styled';

interface Props {
  theme: DefaultTheme;
  className?: string;
  variant?: 'primary' | 'secondary';
  onClickClose?: () => void;
}

const HeaderAside: React.FC<Props> = (props: Props) => {
  const { variant = 'secondary' } = props;

  return (
    <Container className={props.className}>
      <ButtonIcon
        onClick={props.onClickClose}
        icon={variant === 'primary' ? IconCloseLight : IconClose}
        backgroundColor={
          variant === 'primary'
            ? props.theme.colors.backgroundAlpha10
            : props.theme.colors.background
        }
      />
    </Container>
  );
};

export default withTheme(HeaderAside);
