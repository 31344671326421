import React from 'react';

import AssetHamb from 'res/svg/icon-hamb-opt2.svg';
import AssetLogo from 'res/svg/comidaveg-responsive.svg';
import AssetPin from 'res/svg/icon-menu-pin.svg';
import AssetCellphone from 'res/svg/icon-cellphone.svg';
import AssetBack from 'res/svg/icon-back.svg';

import {
  Logo,
  List,
  ListItem,
  ButtonLogo,
  Button,
  Anchor,
  Icon,
  IconAsset,
} from './Menu.styled';

interface Props {
  openList?: boolean;
  openAsideApp?: boolean;
  openAsideRegister?: boolean;
  className?: string;
  onClickLogo?: () => void;
  onClickList?: () => void;
  onClickListMobile?: () => void;
  onClickAsideApp?: () => void;
  onClickAsideRegister?: () => void;
}

const Menu: React.FC<Props> = (props: Props) => {
  return (
    <List className={props.className}>
      <ListItem>
        <ButtonLogo onClick={props.onClickLogo}>
          <Logo src={AssetLogo} />
        </ButtonLogo>
      </ListItem>
      <ListItem>
        <Button
          className={`btn-desktop ${props.openList ? 'active' : ''}`}
          onClick={props.onClickList}
        >
          <Icon>
            <IconAsset src={AssetHamb} />
          </Icon>
        </Button>
        <Button
          className={`btn-mobile ${props.openList ? 'active' : ''}`}
          onClick={props.onClickListMobile}
        >
          <Icon>
            <IconAsset src={AssetHamb} />
          </Icon>
        </Button>
      </ListItem>
      <ListItem>
        <Button
          className={`${props.openAsideRegister ? 'active' : ''}`}
          onClick={props.onClickAsideRegister}
        >
          <Icon>
            <IconAsset src={AssetPin} />
          </Icon>
        </Button>
      </ListItem>
      <ListItem>
        <Button
          className={`${props.openAsideApp ? 'active' : ''}`}
          onClick={props.onClickAsideApp}
        >
          <Icon>
            <IconAsset src={AssetCellphone} />
          </Icon>
        </Button>
      </ListItem>
      <ListItem>
        <Anchor to={'/'}>
          <Icon>
            <IconAsset src={AssetBack} />
          </Icon>
        </Anchor>
      </ListItem>
    </List>
  );
};

export default Menu;
