import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface HeaderProps {
  pathname?: string;
}

export const Icon = styled.img``;

export const List = styled.ul``;

export const Container = styled.div`
  display: none;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: block;
  }
`;

export const Anchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

export const Header = styled.header<HeaderProps>`
  background-color: ${({ pathname }) =>
    pathname === '/estabelecimentos' ? '#FFFFFF' : 'transparent'};
  justify-content: ${({ pathname }) =>
    pathname === '/estabelecimentos' ? 'space-between' : 'flex-end'};

  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 0 2rem;
  width: 100%;
  height: 7.4rem;
`;

export const Title = styled.h1`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: ${({ theme: { typography } }) => typography.button1.fontFamily};
  font-size: ${({ theme: { typography } }) => typography.button1.fontSize};
  margin: 0;
  text-align: center;
  letter-spacing: 0.1pt;
`;

export const ButtonHamb = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.6rem;
  height: 4.6rem;
`;

export const ABack = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.6rem;
  height: 4.6rem;
  text-decoration: none;
`;

export const ButtonClose = styled(ButtonHamb)`
  position: absolute;
  top: 14px;
  right: 20px;
  z-index: 11;
`;

export const Menu = styled.nav`
  background-color: ${({ theme: { colors } }) => colors.background};
  padding: 6.7rem 2rem 4.2rem 2rem;
  width: calc(100% - 2rem);
  height: 100%;
  transform: translateX(100%);
  overflow-x: hidden;
  overflow-y: scroll;

  h2 {
    a {
      font-family: ${({ theme: { typography } }) =>
        typography.button2.fontFamily};
      color: inherit;
      text-decoration: none;
      transition: opacity 0.25s ease-in-out 0s;

      &:hover,
      &:active {
        opacity: 0.5;
      }
    }
  }
`;

export const Modal = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;

  &.open {
    transition: opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
    opacity: 1;
    visibility: visible;

    ${Menu} {
      transition: transform 0.34s ease-in-out 0.2s;
      transform: translateX(0);
    }
  }

  &.close {
    transition: opacity 0.3s ease-in-out 0.3s, visibility 0.3s ease-in-out 0.3s;

    ${Menu} {
      transition: transform 0.34s ease-in-out 0s;
      transform: translateX(100%);
    }
  }

  .mailchimp-form {
    margin-bottom: 10.7rem;

    input {
      color: ${({ theme: { colors } }) => colors.secondary};
      margin-top: 3rem;
      margin-bottom: 1.9rem;
      text-align: left;
      border-bottom-color: ${({ theme: { colors } }) =>
        colors.secondaryAlpha40};

      ::placeholder {
        color: ${({ theme: { colors } }) => colors.secondaryAlpha40};
      }
    }

    button {
      color: ${({ theme: { colors } }) => colors.background};
      background-color: ${({ theme: { colors } }) => colors.primary};
      max-width: 215px;
    }

    .msg-alert p {
      color: ${({ theme: { colors } }) => colors.secondary} !important;
      text-align: left;

      &.error {
        color: ${({ theme: { colors } }) => colors.error} !important;
      }

      &.success {
        font-family: ${({ theme: { typography } }) =>
          typography.button1.fontFamily};
        color: ${({ theme: { colors } }) => colors.whatsapp} !important;
        text-align: center;
      }
    }
  }
`;

export const ListItem = styled.li`
  margin-bottom: 4.2rem;

  a {
    font-size: ${({ theme: { typography } }) => typography.h1Mobile.fontSize};
    font-family: ${({ theme: { typography } }) => typography.h1.fontFamily};
    margin: 0 0 1.1rem 0;
    max-width: 200px;
  }
`;

export const TitleMenu = styled.h1`
  font-size: ${({ theme: { typography } }) => typography.body1.fontSize};
  font-family: ${({ theme: { typography } }) => typography.h1.fontFamily};
  margin: 0 0 2.2rem 0;
`;

export const Copy = styled.p`
  color: ${({ theme: { colors } }) => colors.secondary};
  font-size: ${({ theme: { typography } }) => typography.h2Mobile.fontSize};
  margin-top: 1.3rem;

  a {
    font-family: ${({ theme: { typography } }) =>
      typography.button2.fontFamily};
    color: inherit;
    text-decoration: none;
    transition: opacity 0.25s ease-in-out 0s;

    &:hover,
    &:active {
      opacity: 0.5;
    }
  }
`;
