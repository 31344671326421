import styled, { css } from 'styled-components';

interface Props {
  backgroundColor?: string;
  iconHover?: string;
  active?: boolean;
}

export const WrapLoader = styled.span`
  > div {
    animation: none;
    opacity: 0;
  }
`;

export const IconAsset = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s ease-in-out;
`;

export const ContainerButton = styled.button.attrs({ type: 'button' })<Props>`
  background-color: ${({ theme, backgroundColor = theme.colors.background }) =>
    backgroundColor};

  position: relative;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 5rem;
  transition: background-color 0.25s ease-in-out;

  .circle__Wrapper-sc-16bbsoy-0 {
    position: relative;
    left: 12px;
  }

  &:disabled {
    pointer-events: none;
    cursor: pointer;
  }

  ${({ backgroundColor }) =>
    !backgroundColor &&
    css`
      &:hover {
        background-color: ${({ theme: { colors } }) => colors.tertiary};
      }
    `}

  &:active,
  &:focus {
    opacity: 1;
  }

  &.active {
    background-color: ${({ theme: { colors } }) => colors.primary};
    pointer-events: none;

    ${IconAsset} {
      &:first-of-type {
        opacity: 0;
      }

      &:last-of-type {
        opacity: 1;
      }
    }
  }

  &.is-loading {
    .circle__Wrapper-sc-16bbsoy-0 {
      opacity: 1;
      animation: grgXir 0.75s linear infinite;
    }

    img,
    span {
      opacity: 0 !important;
    }
  }
`;

export const Icon = styled.span<Props>`
  width: inherit;
  height: inherit;

  ${IconAsset} {
    &:last-of-type {
      opacity: 0;
    }
  }
`;

export const ContainerAnchor = styled.a<Props>`
  background-color: ${({ theme, backgroundColor = theme.colors.background }) =>
    backgroundColor};

  display: inline-block;
  position: relative;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 5rem;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.tertiary};
  }

  &:active,
  &:focus {
    opacity: 1;
  }

  &.active {
    ${IconAsset} {
      &:first-of-type {
        opacity: 0;
      }

      &:last-of-type {
        opacity: 1;
      }
    }
  }
`;
