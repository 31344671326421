import React from 'react';
import { useTranslation } from 'react-i18next';

import IconWhatsapp from 'res/svg/icon-whatsapp.svg';
import IconFacebook from 'res/svg/icon-facebook.svg';
import IconTwitter from 'res/svg/icon-twitter.svg';

import {
  List,
  ListItem,
  Media,
  MediaButton,
  MediaMobile,
  Icon,
  IconAsset,
} from './Socials.styled';

interface Props {
  hideText?: boolean;
}

const Socials: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(['common']);

  function sharer(type: string, url: string, winW: number, winH: number) {
    const top = window.outerHeight / 2 + window.screenY - winH / 2;
    const left = window.outerWidth / 2 + window.screenX - winW / 2;

    if (type === 'facebook') {
      window.open(
        `${url}`,
        '',
        `top=${top},left=${left},width=${winW},height=${winH},toolbar=0,status=0`
      );
    }

    if (type === 'twitter') {
      window.open(
        `${url}`,
        '',
        `top=${top},left=${left},width=${winW},height=${winH},toolbar=0,status=0`
      );
    }
  }

  return (
    <List horz={props.hideText}>
      <ListItem variant={'whatsapp'}>
        <MediaMobile>
          <Media
            href={
              'https://wa.me/?text=Comida Veg disponibiliza mapa com mais de 3.000 estabelecimentos com opções de comidas veganas e vegetarianas em São Paulo e região. Acesse https://www.comidaveg.app para saber mais.'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <Icon>
              <IconAsset src={IconWhatsapp} />
            </Icon>
            {!props.hideText && t('common:socials.whatsapp')}
          </Media>
          <Media
            href={
              'whatsapp://send?text=Comida Veg disponibiliza mapa com mais de 3.000 estabelecimentos com opções de comidas veganas e vegetarianas em São Paulo e região. Acesse https://www.comidaveg.app para saber mais.'
            }
            // target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <Icon>
              <IconAsset src={IconWhatsapp} />
            </Icon>
            {!props.hideText && t('common:socials.whatsapp')}
          </Media>
        </MediaMobile>
      </ListItem>
      <ListItem variant={'facebook'}>
        <MediaMobile>
          <MediaButton
            onClick={() =>
              sharer(
                'facebook',
                'https://www.facebook.com/sharer/sharer.php?u=https://www.comidaveg.app&t=Comida Veg disponibiliza mapa com mais de 3.000 estabelecimentos com opções de comidas vegana e vegetariana em São Paulo e região',
                620,
                350
              )
            }
          >
            <Icon>
              <IconAsset src={IconFacebook} />
            </Icon>
            {!props.hideText && t('common:socials.facebook')}
          </MediaButton>
          <Media
            href={
              'https://www.facebook.com/sharer/sharer.php?u=https://www.comidaveg.app&t=Comida Veg disponibiliza mapa com mais de 3.000 estabelecimentos com opções de comidas vegana e vegetariana em São Paulo e região'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <Icon>
              <IconAsset src={IconFacebook} />
            </Icon>
            {!props.hideText && t('common:socials.facebook')}
          </Media>
        </MediaMobile>
      </ListItem>
      <ListItem variant={'twitter'}>
        <MediaMobile>
          <MediaButton
            onClick={() =>
              sharer(
                'twitter',
                'https://twitter.com/intent/tweet?source=webclient&text=Comida Veg disponibiliza mapa com mais de 3.000 estabelecimentos com opções de comidas veganas e vegetarianas em São Paulo e região https://www.comidaveg.app',
                620,
                350
              )
            }
          >
            <Icon>
              <IconAsset src={IconTwitter} />
            </Icon>
            {!props.hideText && t('common:socials.twitter')}
          </MediaButton>
          <Media
            href={
              'https://twitter.com/intent/tweet?source=webclient&text=Comida Veg disponibiliza mapa com mais de 3.000 estabelecimentos com opções de comidas veganas e vegetarianas em São Paulo e região https://www.comidaveg.app'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <Icon>
              <IconAsset src={IconTwitter} />
            </Icon>
            {!props.hideText && t('common:socials.twitter')}
          </Media>
        </MediaMobile>
      </ListItem>
    </List>
  );
};

export default Socials;
