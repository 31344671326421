import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from 'res/svg/comidaveg-logo.svg';
import LogoMobile from 'res/svg/comidaveg-logo-mobile.svg';
import Arrow from 'res/svg/arrow.svg';

import { Center, H1, H2 } from 'components';
import {
  Container,
  Col,
  ImageLogo,
  ImageLogoMobile,
  Label,
  Button,
  Icon,
  IconAsset,
} from './SectionHeader.styled';

const SectionHeader: React.FC = () => {
  const { t } = useTranslation(['common', 'sectionHeader']);

  function scrollWithOffset() {
    const target = document.getElementById('buscar-estabelecimentos');

    if (target) {
      window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
    }
  }

  return (
    <Container>
      <Center>
        <Col>
          <ImageLogo src={Logo} alt={t('sectionHeader:logoALT')} />
          <ImageLogoMobile src={LogoMobile} alt={t('sectionHeader:logoALT')} />
          <H1 dangerouslySetInnerHTML={{ __html: t('sectionHeader:title') }} />
          <H2
            dangerouslySetInnerHTML={{ __html: t('sectionHeader:subtitle') }}
          />
          <Button onClick={() => scrollWithOffset()}>
            <Icon>
              <IconAsset src={Arrow} className={'over'} />
              <IconAsset src={Arrow} className={'normal'} />
            </Icon>
            <Label>{t('common:button.knowMore')}</Label>
          </Button>
        </Col>
      </Center>
    </Container>
  );
};

export default SectionHeader;
