import React from 'react';
import { useTranslation } from 'react-i18next';

// import Asset from 'res/images/avatar.png';
import IconMore from 'res/svg/icon-more.svg';

import {
  Container,
  Header,
  HeaderWrap,
  HeaderInfo,
  // Image,
  Title,
  Subtitle,
  ListType,
  ListTypeItem,
  ButtonMore,
  IconAsset,
} from './Tooltip.styled';

interface Props {
  content?: any;
  onClickMore?: () => void;
}

let types: string[] = [];

const Tooltip: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  if (props.content?.gsx$tiposdealimentosqueoferecematriz) {
    const options = props.content.gsx$tiposdealimentosqueoferecematriz.$t;
    types = options.split(', ');

    // console.log(types);
  }

  return (
    <Container className={'tooltip'}>
      <Header className={'tooltip-header'}>
        <HeaderWrap className={'tooltip-header__wrap'}>
          {/* <Image className={'tooltip-header__image'} src={Asset} /> */}
          <HeaderInfo className={'tooltip-header__info'}>
            <Title className={'tooltip-header__title'}>
              {props.content?.gsx$nomedoestabelecimento.$t}
            </Title>
            <Subtitle className={'tooltip-header__subtitle'}>
              {props.content?.gsx$cozinhatípica.$t}
            </Subtitle>
          </HeaderInfo>
          <ButtonMore id={'btn-more'} onClick={() => console.log('opaaaa')}>
            <IconAsset src={IconMore} />
          </ButtonMore>
        </HeaderWrap>
      </Header>
      <ListType className={'list-type'}>
        <ListTypeItem
          className={`list-type__item ${t('common:types.0.label')} ${
            types.includes(
              'Opções Crudiveganas (Cruas a no máximo com cocção até 40o)'
            )
              ? 'active'
              : ''
          }`}
        >
          {t('common:types.0.label')}
        </ListTypeItem>
        <ListTypeItem
          className={`list-type__item ${t('common:types.1.label')} ${
            types.includes('Opções Veganas') ? 'active' : ''
          }`}
        >
          {t('common:types.1.label')}
        </ListTypeItem>
        <ListTypeItem
          className={`list-type__item ${t('common:types.2.label')} ${
            types.includes('Opções Vegetarianas (Ovolacto)') ? 'active' : ''
          }`}
        >
          {t('common:types.2.label')}
        </ListTypeItem>
        <ListTypeItem
          className={`list-type__item ${t('common:types.3.label')} ${
            types.includes('Opções com carne') ? 'active' : ''
          }`}
        >
          {t('common:types.3.label')}
        </ListTypeItem>
      </ListType>
    </Container>
  );
};

export default Tooltip;
