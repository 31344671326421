import React, { useState } from 'react';

import EstablishmentsLayout from './Layout/EstablishmentsLayout';

import { EstablishmentProps } from './Layout/Types';

const Establishments: React.FC = () => {
  const [toggleList, setToggleList] = useState(false);
  const [toggleListMobile, setToggleListMobile] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [establishmentData, setEstablishmentData] = useState({});
  const [toggleAsideApp, setToggleAsideApp] = useState(false);
  const [toggleAsideRegister, setToggleAsideRegister] = useState(false);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const handleClickList = () => {
    setToggleList(!toggleList);
    setToggleAsideApp(false);
    setToggleAsideRegister(false);
  };

  const handleClickListMobile = () => {
    setToggleListMobile(!toggleListMobile);
    setToggleModal(false);
    setToggleAsideApp(false);
    setToggleAsideRegister(false);
  };

  const handleClickOpenModal = () => {
    setToggleModal(true);
    setToggleListMobile(false);
    setToggleAsideApp(false);
    setToggleAsideRegister(false);
    scrollToTop();
  };

  const handleClickAsideApp = () => {
    setToggleAsideApp(!toggleAsideApp);
    setToggleList(false);
    setToggleListMobile(false);
    setToggleAsideRegister(false);
    setToggleModal(false);
    scrollToTop();
  };

  const handleClickAsideRegister = () => {
    setToggleAsideRegister(!toggleAsideRegister);
    setToggleList(false);
    setToggleListMobile(false);
    setToggleAsideApp(false);
    setToggleModal(false);
    scrollToTop();
  };

  const addEstablishmentToModal = (establishmentData?: EstablishmentProps) => {
    setEstablishmentData(establishmentData || {});
  };

  const handleClickCloseModal = () => {
    setToggleModal(false);
  };

  return (
    <EstablishmentsLayout
      establishmentData={establishmentData}
      isOpenList={toggleList}
      isOpenListMobile={toggleListMobile}
      isOpenModal={toggleModal}
      isOpenAsideApp={toggleAsideApp}
      isOpenAsideRegister={toggleAsideRegister}
      addEstablishmentToModal={addEstablishmentToModal}
      onClickList={handleClickList}
      onClickListMobile={handleClickListMobile}
      onClickOpenModal={handleClickOpenModal}
      onClickCloseModal={handleClickCloseModal}
      onClickAsideApp={handleClickAsideApp}
      onClickAsideRegister={handleClickAsideRegister}
    />
  );
};

export default Establishments;
