import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Icon = styled.span``;

export const IconAsset = styled.img``;

export const Logo = styled.img`
  margin-bottom: 5.1rem;

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    position: relative;
    top: -5px;
    width: 3.6rem;
  }
`;

export const List = styled.ul`
  background-color: ${({ theme: { colors } }) => colors.primary};

  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 6;
  padding: 1.6rem 0;
  width: 7rem;
  min-width: 70px;
  height: 100vh;
  overflow: hidden;

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    z-index: 7;
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    z-index: 2;
    height: 6.9rem;

    &.hide {
      display: none !important;
    }
  }
`;

export const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:nth-child(3) {
    span {
      height: 1.8rem;
    }

    img {
      width: 1.4rem;
    }
  }

  &:nth-child(4) {
    span {
      height: 2.4rem;
    }
  }

  &:last-child {
    position: absolute;
    bottom: 20px;

    span {
      height: 2.5rem;
    }
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    &:not(:first-child) {
      display: none;
    }
  }
`;

export const ButtonLogo = styled.button.attrs({ type: 'button' })`
  &:focus {
    opacity: 1;
  }
`;

const styles = `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 5rem;
  transition: background-color 0.25s ease-in-out 0s;

  &:focus,
  &:active {
    opacity: 1;
  }
`;

export const Button = styled.button.attrs({ type: 'button' })`
  ${styles};

  &.btn-mobile {
    display: none;
  }

  &.active {
    background-color: ${({ theme: { colors } }) => colors.backgroundAlpha10};
  }

  &:hover {
    opacity: 1;
    background-color: ${({ theme: { colors } }) => colors.backgroundAlpha10};
  }

  @media ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    &.btn-desktop {
      display: none;
    }

    &.btn-mobile {
      display: block;
    }
  }
`;

export const Anchor = styled(Link)`
  ${styles};

  &:hover {
    opacity: 1;
    background-color: ${({ theme: { colors } }) => colors.backgroundAlpha10};
  }
`;
